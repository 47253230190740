
import { useContext } from 'react';
import EditUserForm from '../../components/adminComponents/EditUserForm';
import Header from '../../components/Header';
import AuthContext from '../../contexts/userContext';
import { registeredUserInt } from '../../models/login-register-model';


export type ContextType = { user: registeredUserInt | undefined, users: registeredUserInt[] | null};

function ProfilePage() {
  const authCtx = useContext(AuthContext)
  const user = authCtx.user
  const users = authCtx.users
 
 
  let profilePage: boolean = true;

   
  return (
    
    <>
    <Header />            
        {user && users && <EditUserForm user={user as unknown as registeredUserInt} users={users} profilePage={profilePage}  />}
    </>
  );
}

export default ProfilePage;