import { styled} from '@mui/material';
export  const EditEntityDiv = styled("div")(({theme}) =>({
    [theme.breakpoints.up('sm')]:{
      display:"flex",
      justifyContent:"center",
      marginTop:"10px", 
      flexWrap:"wrap", 
      columnGap:"12px",
      maxWidth: "60%",
      margin: "0 auto"
    },
    [theme.breakpoints.up('md')]:{
      display:"flex",
      justifyContent:"center",
      marginTop:"10px", 
      flexWrap:"wrap", 
      columnGap:"12px",
      margin: "0 auto",
      
    },
    [theme.breakpoints.down('sm')]:{
      display:"flex",
      flexDirection:"column" ,
      alignItems:"center",
      marginTop:"10px",
     
    }
   }))