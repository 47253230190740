

import  {  useContext,  useState } from 'react'

import { useParams } from 'react-router-dom'
import EditUserForm from '../components/adminComponents/EditUserForm'

import Loader from '../components/utils/Loader'
import AuthContext from '../contexts/userContext'
import { EditEntityDiv } from '../mediaQuery/EditEntityDiv'

import { registeredUserInt } from '../models/login-register-model'




function EditUserPage() {

  const { userId } = useParams()
  const authCtx = useContext(AuthContext)
  
  const users = authCtx.users
  const user = users.find(user => user.id === userId)
  const handleGetUsers = authCtx.handleGetUsers
  const [isLoading, setIsLoading] = useState(false)
  function setUsersHandler(newUser: registeredUserInt){
       let _users = users
        _users =_users.map(user=> user.id === newUser.id ? newUser : user)
       
        handleGetUsers(_users)     
  }
  

  return (
    <>
    
    <EditEntityDiv>
      {isLoading && <Loader />}
      {user && <EditUserForm user={user} users={users} profilePage={false} setUsersHandler={setUsersHandler} /> }
      {!isLoading && !user && <h3>This user does not exist.</h3>}
    </EditEntityDiv>
    
    
    </>
  )  
  
  
}

export default EditUserPage