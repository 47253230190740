
import { Box, Button } from "@mui/material";
import React, { useContext } from "react";
import { BaseSyntheticEvent, FormEvent } from "react";
import { useForm } from "react-hook-form";


import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { genderStatus, registeredUserInt, registeringUserInt, userRole, userStatus } from "../../models/login-register-model";
import { addDataBeforeApi } from "../../validations/validation";
import { UsersApi } from "../../rest-api";
import FormInputText from "../loginComponents/FormInputText";
import FormSelect from "../loginComponents/FormSelect";
import { validateRegister } from "../../validations/validation";
import AuthContext from "../../contexts/userContext";

export interface GenderSelectOption {
    key: number;
    value: string;
}
export interface StatusSelectOption {
    key: number;
    value: string;
}

export const USER_GENDER_SELECT_OPTIONS: GenderSelectOption[] = Object.keys(genderStatus)
    .filter((item) => !isNaN(Number(item)))
    .map((ordinal: string) => parseInt(ordinal))
    .map((ordinal: number) => ({ key: ordinal, value: genderStatus[ordinal] }));

export const USER_STATUS_SELECT_OPTIONS: StatusSelectOption[] = Object.keys(userStatus)
.filter((item) => !isNaN(Number(item)))
.map((ordinal: string) => parseInt(ordinal))
.map((ordinal: number) => ({ key: ordinal, value: userStatus[ordinal] }));

export const USER_ROLE_SELECT_OPTIONS: StatusSelectOption[] = Object.keys(userRole)
    .filter((item) => !isNaN(Number(item)))
    .map((ordinal: string) => parseInt(ordinal))
    .map((ordinal: number) => ({ key: ordinal, value: userRole[ordinal] }));   

interface AddUserFormProps{
    users: registeredUserInt[],
    formHandler: () => void,
    reloadUsersHandler: () => void
}    

function AddUserForm({users, formHandler, reloadUsersHandler}: AddUserFormProps) {
    const registeringSchema = yup.object({
        firstName:yup.string().required().min(2).max(15),
        lastName: yup.string().required().min(2).max(15),
        userName: yup.string().required().min(5).max(15),
        password: yup.string().required().min(8).matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/gm, "Password must contain at least 8 characters, 1 number and 1 non-number char"),
        password2: yup.string().required().min(8).max(15)
         .oneOf([yup.ref('password'), null], 'Passwords must match!'),
    }).required();
 
    const defaultregisteringUser = { userName: "", password: "", password2:"", firstName: "", lastName:"", gender: genderStatus.Male }
    const [registeringUser, setregisteringUser] = React.useState(defaultregisteringUser)
    const [schema, setSchema] = React.useState(registeringSchema)
    const [errorMsg, setErrorMsg] = React.useState('')
    const authCtx = useContext(AuthContext)
    const token = authCtx.user.token

    const { control, handleSubmit, reset, formState: { errors } } = useForm<Partial<registeringUserInt>>({
        defaultValues: { ...registeringUser },
        mode: 'onChange',
        resolver: yupResolver(schema),
    });
    
    const onSubmit = async  (data: Partial<registeringUserInt>, event: BaseSyntheticEvent<object, any, any> | undefined) => {
        event?.preventDefault();
        
        const userForApi = addDataBeforeApi(data)
       
        const isValid = validateRegister(users, userForApi)
       
        if(isValid.hasOwnProperty('userName')){
            console.log(isValid)
           const serverResp =  await UsersApi.create(isValid as registeringUserInt, token)
           
           
        }else{
            setErrorMsg(isValid as string)
            
        }       
         reset()
         formHandler()
         reloadUsersHandler()

    }
    const onReset = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        
        reset()

    }
  return (
    
    <Box component='form' sx={{ paddingLeft: "30px", paddingRight: "30px", paddingTop: "15px", border:"#00AFF0 solid 1px", borderRadius:"25px", 
    margin:"15px auto"
     }} 
     onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
        {registeringUser &&
            <div style={{ textAlign: "center" }}>
                <h3 style={{margin:"3px"}}>New user form</h3>
                <FormInputText label="First name:" type="text" size="medium" name='firstName' control={control} error={errors.firstName?.message}></FormInputText>
                <FormInputText label="Last name:" type="text" size="medium" name='lastName' control={control} error={errors.lastName?.message}></FormInputText>
                <FormInputText label="Username:" type="text" size="medium" name='userName' control={control} error={errors.userName?.message}></FormInputText><br></br>
                <FormSelect label="Gender:"  size="medium" options={USER_GENDER_SELECT_OPTIONS} defaultOption={registeringUser.gender} name='gender' control={control}  ></FormSelect>
                <FormInputText label="Password:" type="password" size="medium" name='password' control={control} error={errors.password?.message} ></FormInputText>
                <FormInputText label="Confirm password:" type="password" size="medium" name='password2' control={control} error={errors.password2?.message} ></FormInputText>
                {errorMsg && (<p style={{ color: "red" }}>{errorMsg}</p>)}
                <div style={{ justifyContent: "center", display: "flex", margin:"20px auto" }}>
                    <Button variant="outlined"
                        type="reset"
                        color="warning"
                        size="large"

                    >
                        Reset
                        <ClearIcon></ClearIcon>
                    </Button>
                    <Button variant="outlined"
                        type='submit'
                        size="large"
                        sx={{ marginLeft: "20px" }}
                    >Register <SendIcon></SendIcon>
                    </Button>

                </div>
            </div>}
    </Box>
    )
  
}

export default AddUserForm