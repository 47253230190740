import { Avatar, Button, Card, CardContent, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { registeredUserInt } from '../../models/login-register-model'

import SendIcon from '@mui/icons-material/Send';
import { Quote } from '../../models/quotes-author-model';
import { QuotesApi } from '../../rest-api';

export interface newCommentsProps{
    appUser: registeredUserInt,
    quote: Quote,
    rerenderQuoteHandler: (newQuote: Quote) => void
}
function NewComment({appUser, quote, rerenderQuoteHandler}: newCommentsProps) {    

    const [newCommentContent, setNewCommentContent] = useState('')
    async function patchQuote(appUser, quote, newCommentContent){
        let quoteForApi = quote
       
        quoteForApi.comments.push({"userId": appUser.id, "commentContent": newCommentContent})
        const updatedQuote = await QuotesApi.update(quoteForApi, appUser.token)
     
        rerenderQuoteHandler(updatedQuote)
        setNewCommentContent('')
    }
  return (
    <Card sx={{ minWidth: 275, marginTop: "15px" }}>
    <CardContent>
      <div style={{ display: "flex" }}>
        <Avatar src={appUser.imgUrl}>
        </Avatar>
        <div style={{marginLeft:"5px", width:"80%"}}>
          <Typography sx={{ fontSize: "0.9rem" }} color="primay.main" gutterBottom>
            {appUser.userName}
          </Typography>
          <TextField label="Your comment" color="secondary" focused autoFocus={true} sx={{width:"100%"}}
          value={newCommentContent}
            onChange={(event)=>{
                setNewCommentContent(prevValues=>{
                    let _newComment = prevValues
                    
                    _newComment = event.target.value as string
                    return _newComment
                })
            }}
          >{newCommentContent} </TextField>
        </div>
    <Button sx={{height: "60px", width: "30px", alignSelf: "end"}}   
    onClick={()=>{patchQuote(appUser, quote, newCommentContent)}}
    variant="contained" size='small' endIcon={<SendIcon />}>
       
      </Button>
      </div>
    </CardContent>
    
  </Card>
  )
}

export default NewComment