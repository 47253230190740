import React, { useEffect, useMemo, useState } from 'react'
import debounce from 'lodash.debounce';
import { Box, TextField } from '@mui/material';
import { QuotesApi } from '../../rest-api';
import QuoteCard from '../../components/mainComponents/QuoteCard';
import { Quote } from '../../models/quotes-author-model';
import { MainDiv } from '../../mediaQuery/MainDiv';
import Loader from '../../components/utils/Loader';

function SearchPage() {
    const [searchResults, setSearchResults] = useState(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const handleChange = async (e) => {
        console.log(e.target.value)
        if (e.target.value !== "" && e.target.value !== " ") {
            try{
                setIsLoading(true)
                let results = await QuotesApi.searchByText({ text: e.target.value })
                setIsLoading(false)
                setSearchResults(results)
                console.log(results)
            }catch(err){
                console.log(err)
            }
         
        } else {
            setSearchResults(undefined)
        }




    };
    const debouncedResults = useMemo(() => {
        return debounce(handleChange, 500);
    }, []);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    }, [])

    return (
        <>
            <Box 
                component="form"
                sx={{ display:"inline", width:"100%",
                    color: "#00AFF0",
                    '& > :not(style)': { m: 1, width: '25ch' },

                }}
                noValidate
                autoComplete="off"
            >
                <TextField sx={{ color: "yellow" }} autoFocus={true}
                    id="outlined-search" label="Search" multiline={true} helperText="search for author or quote" variant="outlined" onChange={debouncedResults} />
            </Box>
            <MainDiv>
                {isLoading && <Loader />}

                {searchResults?.length === 0 && <h3><i>We couldn`t find any quote or author according to your search parameters.<br></br>Please try another search. </i></h3>}
                {searchResults && searchResults.map(authorAndQuotes => <QuoteCard key={authorAndQuotes[1][0].id} AuthorQuotes={authorAndQuotes} />
                )}

            </MainDiv>
        </>
    )
}

export default SearchPage