import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './pages/App';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import AuthorCard from './components/mainComponents/AuthorCard';
import Welcome from './pages/mainpages/Welcome';
import LoginPage from './pages/mainpages/LoginPage';
import LoginForm from './outlets/LoginForm';
import RegisterForm from './outlets/RegisterForm';
import { AuthContextProvider } from './contexts/userContext';
import Authors from './pages/mainpages/Authors';
import AdminPage from './pages/mainpages/AdminPage';
import UsersPage from './outlets/UsersPage';
import EditUserPage from './outlets/EditUserPage';
import AdminQuotes from './outlets/AdminQuotes';
import EditUserForm from './components/adminComponents/EditUserForm';
import ProfilePage from './pages/mainpages/ProfilePage';
import { ThemeProvider } from '@mui/material/styles';
import { mainTheme } from './themes/theme';
import ErrorPage from './pages/ErrorPage';
import QuotePage from './pages/mainpages/QuotePage';
import AdminAuthors from './outlets/AdminAuthors';
import EditQuotePage from './outlets/EditQuotePage';
import QuoteCommentsPage from './pages/mainpages/QuoteCommentsPage';
import FavoritesPage from './pages/mainpages/FavoritesPage';
import AboutPage from './pages/mainpages/AboutPage';
import SearchPage from './pages/mainpages/SearchPage';

const router = createBrowserRouter([
  {
    path: "/",
    element: < App/>,
    errorElement: <ErrorPage/>,
    children:[     
      {  path: "/",
        element: <Welcome />
      },
      {path:"authors",
        element: <Authors />
    },
    {
      path:"search",
      element: <SearchPage/>
    },
    {
      path: 'authors/:authorId',
      element: <AuthorCard />,
      errorElement: <ErrorPage/>
    },{
      path:"quotes/:quoteId",
      element: <QuotePage />
    },{
      path: "quotes/:quoteId/comments",
      element: <QuoteCommentsPage />
    },
    {path:'*',
      element: <ErrorPage />
    } ,
    {
      path:"profile",
      element: <ProfilePage />
    },
    {
      path:'favorites',
      element: <FavoritesPage />
    },{
      path:"about",
      element: <AboutPage />
    },
    {
      path: "auth",
      element: <LoginPage />,
      children:[
        {path:"login",
        element: <LoginForm />
        },
        {path: "register",
        element : <RegisterForm />,
        }
      ]    
    },
    {
      path:'admin',
      element: <AdminPage />,
      errorElement: <ErrorPage />,
      children:[
        {path:"users",
        element: <UsersPage />},
        {path:"users/:userId",
        element:< EditUserPage />},
        {path: "quotes",
        element: < AdminQuotes />},
        {path:"quotes/:quoteId",
          element: <EditQuotePage />},
        {path:"authors",
         element:<AdminAuthors /> },
        {path:'*',
        element: <ErrorPage />
      }
    ]
    }           
    ]
  },
  
  
]);



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
 
  <React.StrictMode>
  <AuthContextProvider>
    <ThemeProvider theme={mainTheme}>
   <RouterProvider router={router} />
   
   </ThemeProvider>
   </AuthContextProvider>
  </React.StrictMode>
  
);


