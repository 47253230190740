import { Button } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import AddUserForm from '../components/adminComponents/AddUserForm'
import UserCard from '../components/mainComponents/UserCard'
import Loader from '../components/utils/Loader'
import AuthContext from '../contexts/userContext'
import { registeredUserInt } from '../models/login-register-model'
import { UsersApi } from '../rest-api'
import { useMediaQuery, useTheme } from '@mui/material';
import { MainDiv } from '../mediaQuery/MainDiv'

function UsersPage() {
  const theme = useTheme()
  const matchesSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const AuthCtx = useContext(AuthContext)
  const appUser = AuthCtx.user
  const users = AuthCtx.users
  const handleGetUsers = AuthCtx.handleGetUsers
  const [addUserForm, setAddUserForm] = useState(false)
  const [reloadUsers, setReloadUsers] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  
  function reloadUsersHandler() {
    setReloadUsers(!reloadUsers)
  }
  function formHandler() {
    setAddUserForm(!addUserForm)
  }
  useEffect(() => {
    async function getUsers() {
      setIsLoading(true)
      let users = await UsersApi.findAll()
      handleGetUsers(users)
      setIsLoading(false)
    }
    if(reloadUsers){
      getUsers()
      setReloadUsers(false)
    }
    
  }, [reloadUsers])

  return (
    <>
       
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center" }}>
          <h3>Users</h3>         
            {isLoading && <Loader />}
            <div style={!matchesSmall ? { display:"flex", flexDirection:"column", alignItems:"center", margin:"0 auto"} 
                           : {display:"flex", flexDirection:"column", alignItems:"center", margin:"0 auto", width:"50%"}}>
              {users && <Button onClick={() => {
                setAddUserForm(!addUserForm)
              }} sx={{ margin: "5px auto" }} variant="outlined">{!addUserForm ? "Add user" : "Cancel"}</Button>}
              {addUserForm && <AddUserForm reloadUsersHandler={reloadUsersHandler} users={users} formHandler={formHandler} />}
            </div>           
            <MainDiv>
            {users && appUser && users.filter((user: registeredUserInt) => appUser.id !== user.id)
            .map((user: registeredUserInt) =>  <UserCard reloadUsersHandler={reloadUsersHandler} key={user.id} currentUser={user} appUser={appUser} />)}
            </MainDiv>
        </div>
        
        
    </>
  )
}

export default UsersPage