import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useLocation, useNavigate } from 'react-router-dom';

export default function LoginNav() {
    const [value, setValue] = React.useState(0);
    const buttonRef = React.useRef()
    const params = useLocation()
    const arrStrNav = ['Login', 'Register']
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
       
      setValue(newValue);
    };
    const navigate = useNavigate()
    const handleNavChange = (tab: string) =>{
        if(tab === "Login"){
            navigate('/auth/login')
        }else if(tab === 'Register'){
            navigate('/auth/register')
        }
    }
    React.useEffect(()=>{
       
        if(params.pathname.includes('register')){
            handleChange(buttonRef.current, 1)
        }else{
            handleChange(buttonRef.current, 0)
        }
       
       
    },[params])
 

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Tabs value={value} onChange={handleChange} centered >
        {arrStrNav.map((tab,i)=> 
        <Tab ref={buttonRef} onClick={()=>{
            handleNavChange(tab)
        }}
         key={i} label={tab} sx={{padding:"1px"}} />)}
      </Tabs>
    </Box>
  );
}
