import { registeredUserInt } from "../models/login-register-model"
import { Quote } from "../models/quotes-author-model";
import { QuotesApi, UsersApi } from "../rest-api";

export async function likeQuote(userLiker: registeredUserInt, likedQuote: Quote, ){     
      userLiker.likedQuotes.push(likedQuote.id);
      likedQuote.likes += 1;
      let updatedQuote = await QuotesApi.update(likedQuote, userLiker.token)
      let updatedUser = await UsersApi.update(userLiker, userLiker.token)
      return [updatedUser, updatedQuote]
     
   }

   export async function dislikeQuote(userLiker: registeredUserInt, dislikedQuote: Quote, ){   
    userLiker.likedQuotes =userLiker.likedQuotes.filter(quote => quote !== dislikedQuote.id)    
    dislikedQuote.likes -= 1;    
    let updatedQuote = await QuotesApi.update(dislikedQuote, userLiker.token)
    let updatedUser = await UsersApi.update(userLiker, userLiker.token)
    return [updatedUser, updatedQuote]
   
 }

 export async function addFavoriteQuote(userLiker: registeredUserInt, favoriteQuote: Quote, ){
    userLiker.favoriteQuotes.push(favoriteQuote.id);    
    let updatedUser = await UsersApi.update(userLiker, userLiker.token)
    return updatedUser
 }
 export async function removeFavoriteQuote(userLiker: registeredUserInt, favoriteQuote: Quote, ){
    userLiker.favoriteQuotes = userLiker.favoriteQuotes.filter(quoteId => quoteId !== favoriteQuote.id)  
    let updatedUser = await UsersApi.update(userLiker, userLiker.token)
    return updatedUser
 }
