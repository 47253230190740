import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import EditQuoteForm from "../components/adminComponents/EditQuoteForm"
import Loader from "../components/utils/Loader"
import AuthContext from "../contexts/userContext"
import { EditEntityDiv } from "../mediaQuery/EditEntityDiv"
import { Quote } from "../models/quotes-author-model"
import { QuotesApi } from "../rest-api"

function EditQuotePage() {

    const { quoteId } = useParams()
    const authCtx = useContext(AuthContext)
    const [isLoading, setIsLoading] = useState(false)
    const [error,setError] = useState('')
    
    const [quote, setQuote] = useState<Quote | undefined>(undefined)
    
    useEffect(()=>{
      async function getQuote(){
        try{
          setIsLoading(true)
          const foundQuote = await QuotesApi.findById(quoteId)
          setIsLoading(false)
          setQuote(foundQuote)
        }catch(err){
          setError(err)
          setIsLoading(false)
        }
      }
      getQuote()
    }
    
    ,[])
   
    
  
    return (
      <>
      
      <EditEntityDiv>
        {isLoading && <Loader />}
        {quote && <EditQuoteForm quote={quote} />}
        {error && error}
      </EditEntityDiv>
      
      
      </>
    )  
    
    
  }
  
  export default EditQuotePage