import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import QuoteCard from '../../components/mainComponents/QuoteCard'
import Loader from '../../components/utils/Loader'
import { MainDiv } from '../../mediaQuery/MainDiv'
import { Author, Quote } from '../../models/quotes-author-model'
import { AuthorsApi, QuotesApi } from '../../rest-api'

function QuotePage() {
    let params = useParams()   
    let parsedParams = params.quoteId   
    
    const [author, setAuthor] = useState<Author | undefined>(undefined)
    const [quotes, setQuotes] = useState<Quote[] | undefined>(undefined)
    const [error,setError] = useState<string | undefined>(undefined)
    const [authorAndQuotes, setAuthorAndQUotes] = useState(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    useEffect(()=>{
       let subscribed = true;
        async function getAuthorAndQuotes(){
            try{
                setIsLoading(true)
               
                let quote : Quote = await QuotesApi.findById(parsedParams)
                
                let author : Author = await AuthorsApi.findById(quote.authorId)
                let quotes: Quote[] = await QuotesApi.findAll()
                setAuthor(author)
                quotes = quotes.filter(quote => quote.authorId === author.id)
                
                let sortedQuotes = quotes.filter(x => x.id !== quote.id)
                sortedQuotes.unshift(quote)
               
                setQuotes(sortedQuotes)
                let all: any = [author, []]
                for(let quote of sortedQuotes){
                    all[1].push(quote)
                }
                if(subscribed){
                    setAuthorAndQUotes(all)
                }
                
                setIsLoading(false)
            }
            catch(err){
                setError(err)
               setIsLoading(false)
            }

        }
        getAuthorAndQuotes()

        return () =>{
            subscribed = false;
        }

    },[parsedParams])

  return (<MainDiv >
        {isLoading && <Loader />}
        {authorAndQuotes && <QuoteCard AuthorQuotes={authorAndQuotes} />}
        {error && <h3>Sorry, we couldnt find this quote.</h3>}
        </MainDiv>

  )
}

export default QuotePage