import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import EditIcon from '@mui/icons-material/Edit';

import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';




import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useNavigate } from 'react-router-dom';

import {UsersApi } from '../../rest-api';
import { genderStatus, registeredUserInt, userRole, userStatus } from '../../models/login-register-model';
import { Button } from '@mui/material';




interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));
interface UserCardProps {
  currentUser: registeredUserInt,
  appUser: registeredUserInt,
  reloadUsersHandler: () => void
}

export default function UserCard({ currentUser, reloadUsersHandler, appUser }: UserCardProps) {
  const navigate = useNavigate()    
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (currentUser && (
    <Card sx={{
      width: "350px", borderRadius: "10px",

      '&.MuiPaper-root': {
        marginBottom: "15px",
        boxShadow: "1px 1px 1px 1px",
        '&:hover': {
          boxShadow: `green 0px 3px 6px, green 0px 3px 6px`
        },
      }
    }

    }>
      <CardHeader
        sx={{
          '&.MuiPaper-root': {
            width: "100%"

          },
          '&.MuiCardHeader-root': {
            padding: "5px"
          },

          '&.MuiCardHeader-content': {
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            height: "130px"
          }
        }}
        avatar={
          <Avatar

            aria-label="recipe" sx={
              {
                boxShadow: "2px 2px 2px",


                '&.MuiCardHeader-avatar': {
                  width: "150px"
                },
                '&.MuiAvatar-root': {
                  width: '150px',
                  height: "130px",
                  borderRadius: "0px"
                }
              }}
            src={currentUser.imgUrl}
          />
        }

        title={
          <>
            <div style={{ display: "flex", flexDirection: "column", textAlign: "center", alignContent: "start", height: "100px" }}>
              <div> <span>Username:</span>
                <b style={{ fontSize: "1rem", color: "#1e1e1e" }}>{` ${currentUser.userName}`}</b>
              </div>
              <div> <span>Names:</span>
                <b style={{ fontSize: "0.9rem", color: "#1e1e1e" }}>{` ${currentUser.firstName} ${currentUser.lastName}`}</b>
              </div>
              <div> <span>Gender:</span>
                <b style={{ fontSize: "0.9rem", color: "#1e1e1e" }}>{` ${genderStatus[currentUser.gender]}`}</b>
              </div>
              <div> <span>Role:</span>
                <b style={{ fontSize: "0.9rem", color: "#1e1e1e" }}>{` ${userRole[currentUser.role]}`}</b>
              </div>
              <div> <span>Status:</span>
                <b style={{ fontSize: "0.9rem", color: "#1e1e1e" }}>{` ${userStatus[currentUser.status]}`}</b>
              </div>

            </div>
          </>}



      />


      <CardActions disableSpacing>
        <IconButton onClick={handleExpandClick}>
          <DeleteOutlineIcon

          />
        </IconButton>
        <IconButton onClick={() => {
          navigate(`/admin/users/${currentUser.id}`)
        }}>
          <EditIcon />
        </IconButton>

      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
          <h4> Are you sure you want to delete this user?</h4>
          <div>
            <Button
              onClick={handleExpandClick}
            >No</Button>
            <Button
              onClick={async () => {
                console.log(appUser)
                await UsersApi.deleteById(currentUser.id, appUser.token)
                reloadUsersHandler()
              }}
            >Yes</Button>

          </div>
        </CardContent>
      </Collapse>
    </Card>)

  );
}