
import QuoteCard from '../../components/mainComponents/QuoteCard'
import Loader from '../../components/utils/Loader'
import { MainDiv } from '../../mediaQuery/MainDiv'
import AuthContext from '../../contexts/userContext'
import { useCallback, useContext, useEffect, useRef } from 'react'

interface observerInt {
  current: {
    disconnect: () => void,
    observe: (any) => void
  }
}

function Welcome() {
  const authCtx = useContext(AuthContext)
  const { authorsAndQuotes, isLoading, setReload, reload,isMoreData } = authCtx

  const reloadData = useCallback(
    () => {
      console.log('sending fetch?')  
      setReload(!reload)
    }, [reload]
  )

 

  let observer: observerInt = useRef()
  let lastQuoteElement = useCallback(node => {
    if (isLoading) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if(entries[entries.length - 1].isIntersecting){
        console.log('visible')
        reloadData()
      }
    })
    if (node) observer.current.observe(node)
  }, [isLoading])


  return (
    <main>
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}  >
        <>

          <MainDiv >
            {isLoading && <Loader />}
            {authorsAndQuotes.length > 0 && authorsAndQuotes.map((authorQuotes,i) =>{
              if(authorsAndQuotes.length === i+1){                
                return <div  key={authorQuotes[0].id} ref={lastQuoteElement}><QuoteCard  AuthorQuotes={authorQuotes} /></div>
              }else{
                return <QuoteCard key={authorQuotes[0].id} AuthorQuotes={authorQuotes} />
              }
            
             } )
             }
             {!isMoreData &&<h3 style={{color:"#018CF1", width:"90%", margin:"0 auto", marginTop:"15px"}}>
             That`s all for now.
             </h3>}
          </MainDiv>

        </>
      </div>
    </main>
  )
}

export default Welcome