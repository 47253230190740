import { useContext } from 'react'
import AuthContext from '../../contexts/userContext'
import { registeredUserInt, userRole } from '../../models/login-register-model'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Avatar } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import TransitionButton from '../../themes/TransitionButton';
import { IdType, Quote } from '../../models/quotes-author-model';
import { deletedUser } from '../../models/deletedUser';

export interface quoteCommentsProps {
  userId: IdType,
  commentContent: string,
  appUser: registeredUserInt,
  rerenderQuoteHandler: (quote: Quote, number: number) => void,
  quoteComments: { userId: IdType, commentContent: string }[],
  quote: Quote
}






function QuoteComment({ userId, commentContent, appUser, quote, rerenderQuoteHandler, quoteComments }: quoteCommentsProps) {
  const authCtx = useContext(AuthContext)
  const users = authCtx.users
 
  let commentFromUser: registeredUserInt | deletedUser = users.find(user => user.id === userId)
  if(!commentFromUser){
    commentFromUser = new deletedUser()
  }
  

  const userName = commentFromUser.userName
  const userImg = commentFromUser.imgUrl


  return (
    <Card sx={{ minWidth: 275, marginTop: "15px" }}>
      <CardContent>
        <div style={{ display: "flex" }}>
          <Avatar src={userImg}>
          </Avatar>
          <div style={{ marginLeft: "5px", width: "80%" }}>
            <Typography sx={{ fontSize: "0.9rem" }} color="primary.main" gutterBottom>
              {userName}
            </Typography>
            <Typography sx={{ fontSize: "0.8rem" }} color="primary.secondary">
              {commentContent}
            </Typography>

          </div>
          { ( (appUser && userId && appUser.id === userId) || (appUser && appUser.role === userRole.Admin) ) &&
            <div style={{ height: "40px" }}
              onClick={() => {
               
                let quoteReplica = { ...quote }
                let currentCommentIndex = quoteComments.findIndex(comment => (comment.userId === userId && comment.commentContent === commentContent))
               
                quoteReplica.comments.splice(currentCommentIndex, 1)
               
                rerenderQuoteHandler(quoteReplica, 1)
              }}
            >
              <TransitionButton  >
                <DeleteOutlineIcon />
              </TransitionButton>
            </div>
          }
        </div>
      </CardContent>

    </Card>
  );


}

export default QuoteComment