

import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { deepPurple } from '@mui/material/colors';

import { IconButton } from '@mui/material';

const customTheme = createTheme({
  palette: {
    primary: {
      main: deepPurple[500],
    },
  },
});

const StyledAvatar = styled(IconButton)`
  ${({ theme }) => `
  cursor: pointer;
 
  transition: ${theme.transitions.create(['background-color', 'transform'], {
    duration: theme.transitions.duration.complex,
    easing: theme.transitions.easing.easeOut,
  })};
  &:active {
    transform: scale(1.6);
    transform: rotate(-20deg);
    background-color: ${theme.palette.primary.main};
  },
  
  `}
`;

function TransitionButton(props: any) {
  return (
    <StyledAvatar>{props.children}</StyledAvatar>
  )
}

export default TransitionButton