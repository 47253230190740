import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';

import EditIcon from '@mui/icons-material/Edit';

import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

// import MuiEditUserModal from './MuiEditUserModal';
import { Author, Quote } from '../../models/quotes-author-model';
import {  useNavigate } from 'react-router-dom';
import { QuotesApi } from '../../rest-api';
import AuthContext from '../../contexts/userContext';
import { quoteSlicer } from '../utils/quoteSlicer';
import TransitionButton from '../../themes/TransitionButton';
import { Button } from '@mui/material';




type QuoteCardProps = {
  AuthorQuotes: [Author, Quote[]],
  reloadAuthorsHandler: () => void
}


interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function QuoteCard({ AuthorQuotes, reloadAuthorsHandler }: QuoteCardProps) {


  let authCtx = React.useContext(AuthContext)
  const {user} = authCtx
  const [expanded, setExpanded] = React.useState(false);

  const [currentQuote, setCurrentQuote] = React.useState(0)
  const [currentAuthor, setCurrentAuthor] = React.useState(AuthorQuotes[0])
  const [quotes, setQuotes] = React.useState(AuthorQuotes[1])

  const [quoteHeight, setQuoteHeight] = React.useState<string>("120px")
  const [expandContent, setExpandContent] = React.useState<string>('')
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const navigate = useNavigate()
  async function deleteQuote() {
    
    try {
      
      const deletedQuote = await QuotesApi.deleteById(quotes[currentQuote].id, user.token)
      if (deletedQuote) {
          setQuotes(prevValues=>{
             let _quotes = [...prevValues]
             _quotes = _quotes.filter(quote => quote.id !== deletedQuote.id)
             return _quotes
          })
      }
      reloadAuthorsHandler()


    } catch (err) {
      console.log(err)
    }
  }


  React.useEffect(() => {
    setQuotes(AuthorQuotes[1])
  }, [AuthorQuotes])

  // to add logic, that prevents clicking on the invisible arrow for next quote and throws an error!!

  return ( <>
    {(AuthorQuotes && quotes.length > 0 && quotes[currentQuote]) &&
    <Card sx={{
      width: "340px", borderRadius: "10px", boxShadow: `#bdbdbd 2px 2px 2px 2px`,
      '&:hover': {
        boxShadow: `#546e7a 3px 3px 3px 3px`
      },
      '&.MuiPaper-root': {
        marginBottom: "15px"
      }
    }

    }>
      <CardHeader
        sx={{
          '&.MuiPaper-root': { width: "100%" }, '&.MuiCardHeader-root': { padding: "5px" }, '&.MuiCardHeader-content': {
            display: "flex", flexDirection: "column",
            justifyContent: "start",
            height: "130px"
          }
        }}

        avatar={
          <>
            <Avatar aria-label="recipe"
              onClick={() => { navigate(`/authors/${currentAuthor.id}`) }}
              sx={
                {
                  boxShadow: "2px 2px 2px",
                  '&.MuiCardHeader-avatar': {
                    width: "150px"
                  },
                  '&.MuiAvatar-root': {
                    width: '140px',
                    height: "120px",
                    borderRadius: "0px"
                  }
                }}

              src={AuthorQuotes[0].mainPhoto}
            />
          </>
        }
        title={
          <>
            <div style={{ display: "flex", flexDirection: "column", textAlign: "center", alignContent: "start", height: "100px" }}>
              <div style={{ position: "relative", cursor: "help" }}

              >
                <b style={{ fontSize: "1rem", color: "#1e1e1e", cursor: "help" }}
                  onClick={() => navigate(`/authors/${AuthorQuotes[0].id}`)}>
                  {` ${AuthorQuotes[0].name}`}
                </b><br></br>
                {AuthorQuotes[0].occupation && <i><b>({AuthorQuotes[0].occupation})</b></i>}
                <br></br>
                {AuthorQuotes[0].timeOfLiving && <b>{AuthorQuotes[0].timeOfLiving}</b>}
              </div>

            </div>
          </>}

      />
      <CardContent sx={
        {
          display: "flex",
          alignItems: "center",
          height: quoteHeight,
          padding: "0px",
          margin: "10px auto"
        }}>
        <NavigateBeforeIcon
          className={quotes[currentQuote - 1] ? "visibleArrow" : 'hiddenArrow'}
          sx={{
            height: "35px", fontSize: "2rem", color: "#00AFF0", cursor: "pointer"
          }}
          onClick={() => {
            if (quotes[currentQuote - 1]) {
              setCurrentQuote(currentQuote - 1)
            }

          }}
        />
        <Typography variant="body2" color="text.secondary" className='wrapper' sx={{
          '&.MuiTypography-root': {
            textAlign: "center",
            fontWeight: "700",
            fontSize: "0.95rem",
            color: "#1e1e1e",
            width: "95%",
            margin: "10px auto",
            padding: "0",
            cursor: "help",
          }
        }}
          onClick={() => {
            if (quoteHeight === "120px") {
              setQuoteHeight('250px')
            } else {
              setQuoteHeight('120px')
            }

          }}
        >{quoteHeight === '120px' && quotes[currentQuote].content.length > 120 ?
          <i className='typing-demo'>'{quoteSlicer(quotes[currentQuote].content)}'</i>
          : <i className='typing-demo'>'{quotes[currentQuote].content}'</i>}
          <br></br>
          <span style={{ color: "#00AFF0", marginTop: "10px" }}>{currentQuote + 1} / {quotes.length} quotes.</span>

        </Typography>
        <NavigateNextIcon
          className={quotes[currentQuote + 1] ? "visibleArrow" : 'hiddenArrow'}
          sx={{ height: "35px", fontSize: "2rem", color: "#00AFF0", cursor: "pointer" }}
          onClick={() => {
            if (quotes[currentQuote + 1]) {
              setCurrentQuote(currentQuote + 1)
            }
          }}
        />
      </CardContent>
      <CardActions disableSpacing sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start" }}>
        {/* user actions comment */}
        <div style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <div style={{ width: "50%" }}>
            {user.role === 2 &&
              <>
                <TransitionButton >
                  <EditIcon onClick={() => {
                    navigate(`/admin/quotes/${quotes[currentQuote].id}`)
                  }} sx={{ color: "secondary.main" }}
                  />
                </TransitionButton>
                <TransitionButton >
                  <DeleteOutlineIcon sx={{ color: "secondary.main" }}
                    onClick={() => { 
                      handleExpandClick()
                      }}
                  />
                </TransitionButton>
              </>
            }

          </div>
          <div className={expanded ? "showQuoteAction" : "hideQuoteAction"} >
            <span style={{ fontFamily: 'Alfa Slab One, cursive', fontWeight: "300", color: "primary.main" }}>{expandContent}</span>
          </div>


        </div>
        <div style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
          <span>{quotes[currentQuote]?.likes} likes</span>
        </div>
      </CardActions>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent sx={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
          <h4> Are you sure you want to delete this quote?</h4>
          <div>
            <Button
              onClick={handleExpandClick}
            >No</Button>
            <Button
              onClick={ async () => {
                deleteQuote()
                
              }}
            >Yes</Button>

          </div>
        </CardContent>
      </Collapse>

    </Card>}
    </>

  );
}