import { registeredUserInt } from "./models/login-register-model";
import { Author, IdType, Quote } from "./models/quotes-author-model";

interface Identifiable<K> {
    id: K
}

const isProd = !!process.env.REACT_APP_HEROKU_API
const API_BASE_URL = process.env.REACT_APP_HEROKU_API || "http://localhost:4006/api/"


export interface ApiClient<K, V extends Identifiable<K>> {
    findAll(): Promise<V[]>;
    findById(id: K): Promise<V>;
    searchByText(text: { text: string }): Promise<V>;
    create(entityWithoutId: Partial<V>, token: string): Promise<V>;
    update(entity: V, token: string): Promise<V>;
    deleteById(id: K, token: string): Promise<V>;
    getTenMixed(skip, limit): Promise<V[]>;
}

export class ApiClientImpl<K, V extends Identifiable<K>> implements ApiClient<K, V>{
    constructor(public apiCollectionSuffix: string) { }
    getTenMixed(skip, limit): Promise<V[]> {
        return this.handleRequest(`${API_BASE_URL}all?skip=${skip}`, {
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': 'www.onlythoughts.org'
            }
        })
    }
    findAll(): Promise<V[]> {

        return this.handleRequest(`${API_BASE_URL}${this.apiCollectionSuffix}`,
            {
                mode: 'cors',
                headers: {
                    'Access-Control-Allow-Origin': 'www.onlythoughts.org'
                }
            }

        )
    }
    findById(id: K): Promise<V> {
        return this.handleRequest(`${API_BASE_URL}${this.apiCollectionSuffix}/${id}`, {
            mode: 'cors',
            headers: {
                'Access-Control-Allow-Origin': 'www.onlythoughts.org'
            }
        }
        )
    }
    searchByText(text: { text: string }): Promise<V> {
        return this.handleRequest(`${API_BASE_URL}${this.apiCollectionSuffix}/search`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(text)
        })
    }
    create(entityWithoutId: Partial<V>, token: string): Promise<V> {
        console.log(token)
        return this.handleRequest(`${API_BASE_URL}${this.apiCollectionSuffix}`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                "content-type": "application/json",
                Authorization: "Bearer " + token
            },
            body: JSON.stringify(entityWithoutId)
        }
        )
    }
    update(entity: V, token?: string): Promise<V> {
        return this.handleRequest(`${API_BASE_URL}${this.apiCollectionSuffix}/${entity.id}`, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                "content-type": "application/json",
                Authorization: "Bearer " + token
            },
            body: JSON.stringify(entity)
        }

        )
    }
    deleteById(id: K, token?: string): Promise<V> {
        return this.handleRequest(`${API_BASE_URL}${this.apiCollectionSuffix}/${id}`, {
            mode: 'cors',
            headers: {
                "content-type": "application/json",
                'Access-Control-Allow-Origin': 'www.onlythoughts.org',
                Authorization: "Bearer " + token
            },
            method: 'DELETE'
        }
        )
    }

    private async handleRequest(url: string, options?: RequestInit) {

        try {
            const resp = await fetch(url, options);

            if (resp.status >= 400) {
                return Promise.reject(resp.body);
            }
            return resp.json()

        } catch (err) {
            console.log(err)
            return Promise.reject(err)
        }
    }

}
export const QuotesApi: ApiClient<IdType, Quote> = new ApiClientImpl('quotes')
export const AuthorsApi: ApiClient<IdType, Author> = new ApiClientImpl('authors')
export const UsersApi: ApiClient<IdType, registeredUserInt> = new ApiClientImpl('users')