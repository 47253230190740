import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'
import  { BaseSyntheticEvent, FormEvent, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Button } from "@mui/material";
import FormInputText from "../loginComponents/FormInputText";
import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from "react-router-dom";
import { Quote } from "../../models/quotes-author-model";
import { QuotesApi } from "../../rest-api";
import AuthContext from "../../contexts/userContext";


interface editQuoteFormProps {
  quote: Quote 
}

function EditUserForm({ quote }: editQuoteFormProps) {

  const AuthCtx = useContext(AuthContext)
  const token = AuthCtx.user.token
  
  const navigate = useNavigate()

  const schema = yup.object({
    content: yup.string().required().min(2).max(250),
    author: yup.string().required().min(2).max(15)
  }).required();

  const { control, handleSubmit, reset, formState: { errors } } = useForm<Quote>({
    defaultValues: { ...quote },
    mode: 'onChange',
    resolver: yupResolver(schema)

  });

  const onSubmit = async (quote: Quote, event: BaseSyntheticEvent<object, any, any> | undefined) => {
    event?.preventDefault();    
    try{
         await QuotesApi.update(quote, token)
        navigate('/admin/quotes')
    }catch(err){
        console.log(err)
    } 
}

  const onReset = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    reset({ ...quote })
    navigate('/admin/quotes')
  }

  return (quote ?
    <Box component="form" sx={{ display: "flex", flexDirection: "column", width: "100%" }}
      onSubmit={handleSubmit(onSubmit)} onReset={onReset}>

      <div style={{ width: "100%", textAlign: "center" }}>

        <h2>{ `Edit quote with id ${quote.id} info` }</h2>
        <div >
          <FormInputText label="Content:" multiline={true} type="text" name='content' control={control} error={errors.content?.message} ></FormInputText>
          <FormInputText label="Author name:" type="text" name='author' control={control} error={errors.author?.message} ></FormInputText>

        </div>
        <div style={{ justifyContent: "center", display: "flex", marginTop: "10px", marginBottom: "35px" }}>
          <Button variant="outlined"
            type="reset"
            color="warning"
            size="medium"
            
          >
            Cancel
            <ClearIcon></ClearIcon>
          </Button>
          <Button variant="outlined"
            type='submit'
            size="medium"
            sx={{ marginLeft: "15px" }}
          >Edit the data <SendIcon></SendIcon>
          </Button>
        </div>

      </div>


    </Box>
    :
    <h3>This quote does not exist!</h3>
  )
}


export default EditUserForm