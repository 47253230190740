import React from 'react'
import { useNavigate } from 'react-router-dom'

function AboutComp() {
  const navigate = useNavigate()
  return (
    <div style={{display:"block", textAlign:"center", width:"100%", height:"50px"}}>
        <h3 style={{cursor:"pointer"}} onClick={()=>{
          navigate('about')
        }}>About</h3>
    </div>
  )
}

export default AboutComp