import { Box, Button } from "@mui/material";
import React, { useContext, useState } from "react";
import { BaseSyntheticEvent, FormEvent } from "react";
import { useForm } from "react-hook-form";


import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import {  validateAuthor } from "../../validations/validation";
import { AuthorsApi } from "../../rest-api";
import FormInputText from "../loginComponents/FormInputText";
import { RegisteringAuthor } from "../../models/quotes-author-model";
import AuthContext from "../../contexts/userContext";



interface AddAuthorFormInt{
    authors: [] | RegisteringAuthor[],
    formHandler: () => void,
    reloadAuthorsHandler: () => void
}    

function AddAuthorForm({authors, formHandler, reloadAuthorsHandler}: AddAuthorFormInt) {
    const registeringSchema = yup.object({
        name:yup.string().required().min(2).max(50),
        biography: yup.string().min(2).max(4500),
        mainPhoto:yup.string().url()       
    }).required();
 
    const defaultRegisteringAuthor = { name: "", biography:"",timeOfLiving:"", occupation:"", mainPhoto:""}
    const [registeringAuthor, setRegisteringAuthor] = useState<RegisteringAuthor>(defaultRegisteringAuthor)
    const [schema, setSchema] = useState(registeringSchema)
    const [errorMsg, setErrorMsg] = useState('')
    const authCtx = useContext(AuthContext)
    const setReload =authCtx.setReload
    const reload = authCtx.reload
    const token = authCtx.user.token
    const { control, handleSubmit, reset, formState: { errors } } = useForm<Partial<RegisteringAuthor>>({
        defaultValues: { ...registeringAuthor },
        mode: 'onChange',
        resolver: yupResolver(schema),
    });
    const onSubmit = async  (data: RegisteringAuthor, event: BaseSyntheticEvent<object, any, any> | undefined) => {
        event?.preventDefault();      
       
       let authors = await AuthorsApi.findAll()
        const isValid = validateAuthor(authors, data)
        if(isValid.hasOwnProperty('name')){
            await AuthorsApi.create(isValid as RegisteringAuthor, token)
           
        }else{
            setErrorMsg(isValid as string)
            
        }       
         reset()
         formHandler()
         setReload(!reload)

    }
    const onReset = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();        
        reset()

    }
  return (
    
    <Box component='form' sx={{ paddingLeft: "30px", paddingRight: "30px", paddingTop: "15px", border:"#00AFF0 solid 1px", borderRadius:"25px", 
    margin:"15px auto"
     }} 
     onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
        {registeringAuthor &&
            <div style={{ textAlign: "center" }}>
                <h3 style={{margin:"3px"}}>New author form</h3>
                <FormInputText label="Name:" type="text" size="medium" name='name' control={control} error={errors.name?.message}></FormInputText>
                <FormInputText label="Biography:" type="text" multiline={true} size="medium" name='biography' control={control} error={errors.biography?.message}></FormInputText>
                <FormInputText label="Occupation:" type="text" size="medium" name='occupation' control={control} error={errors.occupation?.message}></FormInputText>            
                <FormInputText label="Time of living:" type="text" size="medium" name='timeOfLiving' control={control} error={errors.timeOfLiving?.message} ></FormInputText>
                <FormInputText label="Main photo:" type="url" size="medium" name='mainPhoto' control={control} error={errors.mainPhoto?.message} ></FormInputText>
                {errorMsg && (<p style={{ color: "red" }}>{errorMsg}</p>)}
                <div style={{ justifyContent: "center", display: "flex", margin:"20px auto" }}>
                    <Button variant="outlined"
                        type="reset"
                        color="warning"
                        size="large"

                    >
                        Reset
                        <ClearIcon></ClearIcon>
                    </Button>
                    <Button variant="outlined"
                        type='submit'
                        size="large"
                        sx={{ marginLeft: "20px" }}
                    >Register <SendIcon></SendIcon>
                    </Button>

                </div>
            </div>}
    </Box>
    )
  
}

export default AddAuthorForm