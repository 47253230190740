import {  registeredUserInt} from "./login-register-model";

export class deletedUser implements  Partial<registeredUserInt>{
    imgUrl: string;
    userName: string;
    
    constructor(){
        this.userName = 'Deleted User';    
        this.imgUrl = 'https://cdn2.iconfinder.com/data/icons/mutuline-ui-essential/48/delete_user_remove-512.png';
    }
    
    
   

}