import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { registeredUserInt } from '../models/login-register-model';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../contexts/userContext';
import MenuIcon from '@mui/icons-material/Menu';

export default function FadeMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const authCtx = React.useContext(AuthContext)
  const user: registeredUserInt = authCtx.user 
  const navigate = useNavigate()
  const {pathname} = useLocation()
  

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if(user){
      setAnchorEl(event.currentTarget);
    }else{
      
     if(pathname === '/auth/login' || pathname==="/auth/register"){
        navigate('/')
     }else{
        navigate('/auth/login')
     }
      
    }
    
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{display: "flex", flexDirection:"column", justifyContent:"center"}}>
      <Button
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {user? <MenuIcon />:  (pathname.includes('/login') || pathname.includes('/register')? "Trends" : "Login" ) }
      
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        sx={{width:"110px", borderRadius:"10px", borderColor:"primary",
        '& .MuiButtonBase-root':{
            paddingLeft: "4px",
            paddingRight: "4px",
            paddingTop: "3px",
            paddingBottom: "3px",
        },
    }}
      >{ !pathname.includes('profile') && 
        <MenuItem         
        onClick={()=> {
          handleClose()
          navigate('/profile')}}>
          Profile
        </MenuItem>}
        {user?.role === 2 && (pathname !=='/' && pathname !=='/authors'  && pathname !=='/news' && pathname !=='/search')  &&
         <MenuItem onClick={()=>{
          handleClose()
          navigate('/')
        }} > 
        Trends
        </MenuItem>}
         {!pathname.includes('favorites') && <MenuItem onClick={()=> navigate('/favorites')}>
          Favorites
        </MenuItem>}
        {user?.role === 2 && !pathname.includes('admin') &&
         <MenuItem onClick={()=>{
            navigate('/admin')
            handleClose()
        }} sx={{border:"2px solid rgba(255,0,0,0.3)", borderRadius:"5px"}}>
          Admin<br></br> panel 
        </MenuItem>}
        <MenuItem onClick={()=>{
          if(user){
            authCtx.logout()
            handleClose()
          }
        }}>
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}