import { Button, useMediaQuery, useTheme } from '@mui/material'
import  { useContext, useEffect, useState } from 'react'
import AddQuoteForm from '../components/adminComponents/AddQuoteForm'
import EditQuoteCard from '../components/adminComponents/EditQuoteCard'


import AuthContext from '../contexts/userContext'
import { MainDiv } from '../mediaQuery/MainDiv'
import { Author, Quote } from '../models/quotes-author-model'
import { AuthorsApi } from '../rest-api'


function AdminQuotes() {
  const theme = useTheme()
  const matchesSmall = useMediaQuery(theme.breakpoints.up('sm'));
  const [addQuoteForm, setAddQuoteForm] = useState<boolean>(false)
  const authCtx = useContext(AuthContext)
  const { reload, setReload, user: appUser, authorsAndQuotes } = authCtx

  const [quotes, setQuotes] = useState<Quote[] | []>([])
  const [authors, setAuthors] = useState([])
  useEffect(()=>{
    async function getAuthors() {
      const authorsFromApi = await AuthorsApi.findAll()
      setAuthors(authorsFromApi)
    }
    getAuthors()
    
  },[reload])
  function reloadAuthorsHandler() {
    
    setReload(!reload)
  }
  function formHandler() {
    setAddQuoteForm(!addQuoteForm)
  }
  // useEffect(() => {
  //   let quotes = [] as Quote[]

  //   authorsAndQuotes.map(all => {
     
  //     quotes.push(all[1] as Quote)
  //     if (!authors.includes(all[0])) {
  //       authors.push(all[0] as Author)
  //     }

  //   })
  //   setQuotes(quotes)
   

  // }, [reload, authorsAndQuotes])


  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center" }}>
      <h3 style={{ margin: "0 auto" }}>Admin quotes:</h3>

      <div style={!matchesSmall ? { display: "flex", flexDirection: "column", alignItems: "center", margin: "0 auto" }
        : { display: "flex", flexDirection: "column", alignItems: "center", margin: "0 auto", width: "50%" }}>
        {authorsAndQuotes && quotes &&
          <Button onClick={() => {
            setAddQuoteForm(!addQuoteForm)
          }} sx={{ margin: "5px auto" }} variant="outlined">{!addQuoteForm ? "Add new Quote" : "Cancel"}
          </Button>}
        {addQuoteForm && <AddQuoteForm reloadAuthorsHandler={reloadAuthorsHandler} appUser={appUser} authors={authors} quotes={quotes} formHandler={formHandler} />}
      </div>
      <MainDiv>
        {authorsAndQuotes && authorsAndQuotes.map(authorQuote => <EditQuoteCard reloadAuthorsHandler={reloadAuthorsHandler} key={authorQuote[0].id} AuthorQuotes={authorQuote} />)}
      </MainDiv>
    </div>


  )
}

export default AdminQuotes