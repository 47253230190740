
import React, { useCallback, useContext, useEffect, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import AuthorCard from '../../components/mainComponents/AuthorCard'
import Loader from '../../components/utils/Loader'
import AuthContext from '../../contexts/userContext'
import useMainFetchAndSort from '../../customHooks/useMainFetchAndSort'
import { MainDiv } from '../../mediaQuery/MainDiv'

interface observerInt {
  current: {
    disconnect: () => void,
    observe: (any) => void
  }
}

function Authors() {
  const authCtx = useContext(AuthContext)
  const { isLoading, authors, reload, setReload,isMoreData } = authCtx

  const reloadData = useCallback(
    () => {
      console.log('sending fetch?')  
      setReload(!reload)
    }, [reload]
  )

 

  let observer: observerInt = useRef()
  let lasElement = useCallback(node => {
    if (isLoading) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if(entries[entries.length - 1].isIntersecting){
        console.log('visible')
        reloadData()
      }
    })
    if (node) observer.current.observe(node)
  }, [isLoading])
  
  return (
    <>
         <div style={{display:"flex",flexDirection:"column", justifyContent:"center"}}>                   
        
         {isLoading && <Loader />}
        <MainDiv>        
          
          {authors && authors.map((author,i) => {
             if(authors.length === i+1){                
              return <div  key={author.id} ref={lasElement}> <AuthorCard key={author.id} author={author}  /></div>
            }else{
               return <AuthorCard key={author.id} author={author}  />
            }          
          })}
          {!isMoreData && <h3 style={{color:"#018CF1", width:"90%", margin:"0 auto"}}>
              That`s all for now.</h3>}
        </MainDiv>
       
      </div>
    </>
  )
}

export default Authors