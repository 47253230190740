
import { useMediaQuery, useTheme } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import NewComment from '../../components/commentsComponents/NewComment'
import QuoteComment from '../../components/commentsComponents/QuoteComment'
import AuthContext from '../../contexts/userContext'
import { Quote } from '../../models/quotes-author-model'
import { QuotesApi } from '../../rest-api'

function QuoteCommentsPage() {
    const params = useParams()
    const quoteId = params.quoteId
    const authCtx = useContext(AuthContext)
    const appUser = authCtx.user
    const theme = useTheme()
    const matchesSmall = useMediaQuery(theme.breakpoints.up('sm'));

    const [quote,setQuote] = useState<Quote | undefined>(undefined)
    

    const rerenderQuoteHandler = async (newQuote: Quote, deleteQuoteComment: number = 0) =>{     
      if(deleteQuoteComment === 1){
        await QuotesApi.update(newQuote, appUser.token)
      } 
        setQuote(newQuote)     
      
    }

    useEffect(()=>{      
      let subscribed = true  
    async function getQuote(){      
        const foundQuote = await QuotesApi.findById(quoteId) 
         if(subscribed){
            setQuote(foundQuote)
         }
       
    }
    getQuote()

    return () =>{
      subscribed = false;
    }
    },[])

  
  return (
    <div>
        {quote && (quote?.comments?.length > 0) && quote?.comments?.map( 
          (comment,i) => <QuoteComment key={i} quoteComments={quote.comments} rerenderQuoteHandler={rerenderQuoteHandler} 
          appUser={appUser} userId={comment.userId} quote={quote} commentContent={comment.commentContent} /> )}
        {quote && (quote?.comments?.length < 1) && 
        <div style={matchesSmall ? {width:"65%", margin:"0 auto"}: {width:"90%", margin:"0 auto"}}>
        <h3>There are still no comments for this quote: <br></br> "{quote.content}" Maybe you can write the first one?</h3>
        </div>}
        {appUser && <NewComment rerenderQuoteHandler={rerenderQuoteHandler} quote={quote} appUser={appUser} /> }
        </div>
  )
}

export default QuoteCommentsPage