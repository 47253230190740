
import  { useContext, useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import AuthContext from '../../contexts/userContext'

function AdminPage() {
    const navigate = useNavigate()
    const AuthCtx = useContext(AuthContext)
    let user = AuthCtx.user
    useEffect(()=>{
      if(user?.role !== 2){
        navigate('/',)
      }
      
    },[user])
    
  return (    
    <Outlet />       
  )
}

export default AdminPage