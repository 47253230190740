import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup'
import  { BaseSyntheticEvent, FormEvent, useContext } from 'react'
import { useForm } from 'react-hook-form'
import { registeredUserInt } from "../../models/login-register-model";
import { UsersApi } from "../../rest-api";
import { Box, Button } from "@mui/material";
import FormInputText from "../loginComponents/FormInputText";
import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from "react-router-dom";
import FormSelect from "../loginComponents/FormSelect";
import { USER_GENDER_SELECT_OPTIONS, USER_ROLE_SELECT_OPTIONS, USER_STATUS_SELECT_OPTIONS } from "./AddUserForm";
import { addDataBeforeUserEditApi } from "../../validations/validation";
import AuthContext from "../../contexts/userContext";


interface editUserFormProps {
  user: registeredUserInt,
  users: registeredUserInt[],
  setUsersHandler?: (newUser: registeredUserInt) => void,
  profilePage?: boolean
}

function EditUserForm({ user, users, profilePage, setUsersHandler }: editUserFormProps) {

  const AuthCtx = useContext(AuthContext)
  const token = AuthCtx.user.token
 
  const navigate = useNavigate()
  const schema = yup.object({
    firstName: yup.string().required().min(2).max(15),
    lastName: yup.string().required().min(2).max(15),
    userName: yup.string().required().min(5).max(15),
    description: yup.string().min(2).max(1024)
  }).required();

  const { control, handleSubmit, reset, formState: { errors } } = useForm<registeredUserInt>({
    defaultValues: { ...user, newImgUrl: "" },
    mode: 'onChange',
    resolver: yupResolver(schema)

  });

  const onSubmit = async (data: registeredUserInt, event: BaseSyntheticEvent<object, any, any> | undefined) => {
    event?.preventDefault();
    let editedUser = addDataBeforeUserEditApi(data, users)
    if(typeof editedUser === 'string'){
        errors.userName.message = editedUser
    }else{
      let updatedUser = await UsersApi.update(editedUser as registeredUserInt, token)
     
      if (!profilePage) {
        setUsersHandler(updatedUser)        
        navigate('/admin/users')
      } else {

        AuthCtx.login(updatedUser)
        navigate('/')
      }
    }
    
    

  }

  const onReset = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    reset({ ...user })
  }

  return (user ?
    <Box component="form" sx={{ display: "flex", flexDirection: "column", width: "100%" }}
      onSubmit={handleSubmit(onSubmit)} onReset={onReset}>

      <div style={{ width: "100%", textAlign: "center" }}>

        <h2>{!profilePage ? `Edit ${user.userName} info` : 'Edit your profile'}</h2>
        <div >
          <FormInputText label="First name:" type="text" name='firstName' control={control} error={errors.firstName?.message} ></FormInputText>
          <FormInputText label="Last name:" type="text" name='lastName' control={control} error={errors.lastName?.message} ></FormInputText>
          <FormInputText label="Username:" type="text" name='userName' control={control} error={errors.userName?.message}></FormInputText>
          <div style={{ margin: "0px auto", display: "flex", flexDirection: "column", alignItems:"center" }}>
            <img src={user.imgUrl} style={{ maxWidth: "60%", maxHeight: "300px", margin: "0 auto" }} alt='user portrait'></img>
            <FormInputText label="Put the URL for the new img:" type="text" name='newImgUrl' control={control} error={errors.newImgUrl?.message}></FormInputText>
          </div>
          <FormInputText label="Description:" type="text" name='description' multiline={true} control={control} error={errors.description?.message}></FormInputText>
          <FormSelect label="Gender:" options={USER_GENDER_SELECT_OPTIONS} defaultOption={user.gender} name='gender' control={control} />
          {profilePage ? "" :
           <div>
              <FormSelect label="Status:" options={USER_STATUS_SELECT_OPTIONS} defaultOption={user.status} name='status' control={control}  ></FormSelect>
              <FormSelect label="Role:" options={USER_ROLE_SELECT_OPTIONS} defaultOption={user.role} name='role' control={control}  ></FormSelect>
            </div>
          }

        </div>
        <div style={{ justifyContent: "center", display: "flex", marginTop: "10px", marginBottom: "35px" }}>
          <Button variant="outlined"
            type="reset"
            color="warning"
            size="medium"
            onClick={() => {
              navigate('/admin/users')
            }}
          >
            Cancel
            <ClearIcon></ClearIcon>
          </Button>
          <Button variant="outlined"
            type='submit'
            size="medium"
            sx={{ marginLeft: "15px" }}
          >Edit the data <SendIcon></SendIcon>
          </Button>
        </div>

      </div>


    </Box>
    :
    <h3>This user does not exist!</h3>
  )
}


export default EditUserForm
