import { styled} from '@mui/material';
export  const MainDiv = styled("div")(({theme}) =>({
    [theme.breakpoints.up('sm')]:{
      display:"flex",
      flexDirection:"row", 
      justifyContent:"center",
      marginTop:"10px", 
      flexWrap:"wrap", 
      columnGap:"12px", 
      margin:"15px auto",
      textAlign:"center"
    },
    [theme.breakpoints.down('sm')]:{
      display:"flex",
      flexDirection:"column" ,
      alignItems:"center",
      marginTop:"10px",
      margin:"15px auto",
      textAlign:"center"
    }
   }))