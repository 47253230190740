
import { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate,  useParams } from 'react-router-dom';
import AdminNav from '../components/adminComponents/AdminNav';

import Header from '../components/Header';
import LoginNav from '../components/loginComponents/LoginNav';
import AboutComp from '../components/mainComponents/AboutComp';

import Navigation from '../components/Navigation';
import AuthContext from '../contexts/userContext';
import { MainDiv } from '../mediaQuery/MainDiv';
import { registeredUserInt } from '../models/login-register-model';
import { UsersApi } from '../rest-api';
export type ContextType = { user: registeredUserInt | undefined, users: registeredUserInt[] | null};

function App() {
  
  const navigate = useNavigate()
  const location = useLocation()
  const pathname = location.pathname
  const params = useParams()
  const AuthCtx = useContext(AuthContext)
  
  const handleGetUsers = AuthCtx.handleGetUsers
  const [isLoading, setIsLoading] = useState(false)

  useEffect(()=>{
      
      async function getUsers(){
          setIsLoading(true)
          let users = await UsersApi.findAll()
          handleGetUsers(users)
          setIsLoading(false)
          
      }
      getUsers()
      if(!params.hasOwnProperty('quoteId') && !params.hasOwnProperty('authorId') && !params.hasOwnProperty('userId')){
        navigate('/') 
      }
  
  },[])
  
  
    
  return (
    <div className="App" style={{display:"flex", flexDirection:"column", justifyContent:"center", textAlign:"center"}}>
     <Header />
     {(pathname === "/" || pathname === '/authors' || pathname === '/search') && <Navigation />}
     {pathname.includes('admin') && <AdminNav />}
     {pathname.includes('auth') && !pathname.includes('author') && <LoginNav />}
     {pathname.includes('favorites') && <h3>Your favorite quotes:</h3>}
     <MainDiv >
        <Outlet />
     </MainDiv>
     {!pathname.includes('about') && <AboutComp />}
    </div>
  );
}

export default App;


