
import { Box, Button } from "@mui/material";
import React, { useContext } from "react";
import { BaseSyntheticEvent, FormEvent } from "react";
import { useForm } from "react-hook-form";
import { ApiRes, genderStatus, registeringUserInt} from "../models/login-register-model";

import FormInputText from "../components/loginComponents/FormInputText";
import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { addDataBeforeApi, validateRegister } from "../validations/validation";
import AuthContext from "../contexts/userContext";
import { useNavigate } from "react-router-dom";
import FormSelect from "../components/loginComponents/FormSelect";
import { UsersApi } from "../rest-api";
import { AuthApi, userCredentials } from "../auth-api";
import { useCookies } from "react-cookie";


export interface GenderSelectOption {
    key: number;
    value: string;
}
const USER_GENDER_SELECT_OPTIONS: GenderSelectOption[] = Object.keys(genderStatus)
    .filter((item) => !isNaN(Number(item)))
    .map((ordinal: string) => parseInt(ordinal))
    .map((ordinal: number) => ({ key: ordinal, value: genderStatus[ordinal] }));



function RegisterForm() {
    const registeringSchema = yup.object({
        firstName: yup.string().required().min(2).max(15),
        lastName: yup.string().required().min(2).max(15),
        userName: yup.string().required().min(5).max(15),
        password: yup.string().required().min(8).matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/gm, "Password must contain at least 8 characters, 1 number and 1 non-number char"),
        password2: yup.string().required().min(8).max(15)
            .oneOf([yup.ref('password'), null], 'Passwords must match!'),
    }).required();


    const [cookies, setCookie, removeCookie] = useCookies(['OTauth']);
    const authCtx = useContext(AuthContext)
    const {users, handleGetUsers} = authCtx

    const defaultregisteringUser = { userName: "", password: "", password2: "", firstName: "", lastName: "", gender: genderStatus.Male }
    const [registeringUser, setregisteringUser] = React.useState(defaultregisteringUser)
    const [schema, setSchema] = React.useState(registeringSchema)
    const [errorMsg, setErrorMsg] = React.useState('')
    const navigate = useNavigate()

    const { control, handleSubmit, reset, formState: { errors } } = useForm<registeringUserInt>({
        defaultValues: { ...registeringUser },
        mode: 'onChange',
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data: registeringUserInt, event: BaseSyntheticEvent<object, any, any> | undefined) => {
        event?.preventDefault();     
            const userForApi: userCredentials = addDataBeforeApi(data) as userCredentials
           
            const isValid : ApiRes | Error  = await AuthApi.register(userForApi) as unknown as ApiRes
            const isUser = isValid.hasOwnProperty('user')
        if (isUser) { 
            
            setCookie(
                "OTauth",
                {token: isValid.token, id: isValid.user.id},
                {expires: new Date(Date.now() + 3600000)}
            )
            isValid.user.token = isValid.token
            authCtx.login(isValid.user)
            navigate('/')
            const users = await UsersApi.findAll()
            handleGetUsers(users)
            
        } else {
            setErrorMsg('Problem with the registration.')

        }
        
    }
    const onReset = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        reset()

    }
    return (

        <Box component='form' sx={{ paddingLeft: "30px", paddingRight: "30px", paddingTop: "15px", margin: "10px auto", border: "1px solid #018CF1", borderRadius: "25px", paddingBottom: "15px" }}
            onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
            <div style={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <FormInputText label="First name:" type="text" size="medium" name='firstName' control={control} error={errors.firstName?.message}></FormInputText>
                <FormInputText label="Last name:" type="text" size="medium" name='lastName' control={control} error={errors.lastName?.message}></FormInputText>
                <FormInputText label="Username:" type="text" size="medium" name='userName' control={control} error={errors.userName?.message}></FormInputText>
                <div style={{ width: "50%" }}>
                    <FormSelect label="Gender:" size="small" options={USER_GENDER_SELECT_OPTIONS} defaultOption={registeringUser.gender} name='gender' control={control}  ></FormSelect>
                </div>

                <FormInputText label="Password:" type="password" size="medium" name='password' control={control} error={errors.password?.message} ></FormInputText>
                <FormInputText label="Confirm password:" type="password" size="medium" name='password2' control={control} error={errors.password2?.message} ></FormInputText>
                {errorMsg && (<p style={{ color: "red" }}>{errorMsg}</p>)}
                <div style={{ justifyContent: "center", display: "flex", marginTop: "30px" }}>
                    <Button variant="outlined"
                        type="reset"
                        color="warning"
                        size="large"

                    >
                        Reset
                        <ClearIcon></ClearIcon>
                    </Button>
                    <Button variant="outlined"
                        type='submit'
                        size="large"
                        sx={{ marginLeft: "15px" }}
                    >Register <SendIcon></SendIcon>
                    </Button>

                </div>
            </div>
        </Box>
    )

}

export default RegisterForm