import React from 'react'
import { useNavigate } from 'react-router-dom'

import HeaderMenu from './HeaderMenu'

function Header() {
  const navigate = useNavigate()
  return (
    <header style={{display:"flex", justifyContent:"center"}}>
      <div style={{display:"flex", justifyContent:"space-between",position:"fixed",top:"0", width:"95%", margin:"0 auto",
            backgroundColor:"white", border:"1px solid #018CF1", borderRadius:"0px 0px 25px 25px", zIndex:"1000" }}>
          <HeaderMenu />
          <img style={{justifySelf:"center", cursor:"pointer"}} alt='logo' src={require('../OT - materials/WhiteLogo190x70.jpg')} onClick={()=>{navigate('/')}}></img>
          <div style={{width:"64px"}}></div>
      </div>
    </header>
  )
}

export default Header