import { Box, Button } from "@mui/material";
import React, { useContext } from "react";
import { BaseSyntheticEvent, FormEvent } from "react";
import { useForm } from "react-hook-form";
import { ApiRes,  registeringUserInt } from "../models/login-register-model";
import {useCookies} from 'react-cookie'
import FormInputText from "../components/loginComponents/FormInputText";
import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import AuthContext from "../contexts/userContext";
import { useNavigate } from "react-router-dom";
import { AuthApi } from "../auth-api";



const loggingSchema = yup.object({
    userName: yup.string().required().min(5).max(15),
    password: yup.string().min(8).matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/gm, "Password must contain at least 8 characters, 1 number and 1 non-number char")
}).required();

function LoginForm() {    
    const [cookies, setCookie, removeCookie] = useCookies(['OTauth']);
    const authCtx = useContext(AuthContext)    
    
    const defaultLogingUser = { userName: "", password: "" }
    const [loggingUser, setLoggingUser] = React.useState(defaultLogingUser)
    const [schema, setSchema] = React.useState(loggingSchema)
    const [errorMsg, setErrorMsg] = React.useState('')
    const navigate = useNavigate()

    const { control, handleSubmit, reset, formState: { errors } } = useForm<registeringUserInt>({
        defaultValues: { ...loggingUser },
        mode: 'onChange',
        resolver: yupResolver(schema),
    });
    const onSubmit = async (data: registeringUserInt, event: BaseSyntheticEvent<object, any, any> | undefined) => {
        event?.preventDefault();
   
        try{
           
            let isValid: ApiRes | Error = await AuthApi.login(data) as unknown as ApiRes
            
            const isUser = isValid.hasOwnProperty('user')
        if (isUser) {                     
           
            setCookie(
                "OTauth",
                {token: isValid.token, id: isValid.user.id},
                {expires: new Date(Date.now() + 3600000)}
            )
            isValid.user.token = isValid.token
            authCtx.login(isValid.user)
          
            reset()
            navigate('/')
        }else {
            setErrorMsg('error')
        }}catch(err){
            
            setErrorMsg('Invalid username or password.')
        }    
        
            
         
       
    }
    const onReset = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        
        reset()

    }
    return (
        
        <Box component='form' sx={{ paddingLeft: "30px", paddingRight: "30px", paddingTop: "15px",margin:"10px auto", width:"300px", alignSelf:"center" }} onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
            <div onClick={()=>setErrorMsg('')}
             style={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <FormInputText label="Username:" type="text" size="medium" name='userName' control={control} error={errors.userName?.message}></FormInputText>
                <FormInputText label="Password:" type="password" size="medium" name='password' control={control} error={errors.password?.message} ></FormInputText>
                {errorMsg && (<p style={{ color: "red" }}>{errorMsg}</p>)}
                <div style={{ justifyContent: "center", display: "flex", marginTop: "10px" }}>
                    <Button variant="outlined"
                        type="reset"
                        color="warning"
                        size="large"

                    >
                        Reset
                        <ClearIcon></ClearIcon>
                    </Button>
                    <Button variant="outlined"
                        type='submit'
                        size="large"
                        sx={{ marginLeft: "15px" }}
                    >Login <SendIcon></SendIcon>
                    </Button>

                </div>
            </div>
        </Box>
    )
}

export default LoginForm