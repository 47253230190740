import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import { Author, Quote } from '../../models/quotes-author-model';
import { registeredUserInt } from '../../models/login-register-model';
import AuthContext from '../../contexts/userContext';
import { addFavoriteQuote, dislikeQuote, likeQuote, removeFavoriteQuote } from '../../userActions/addLikesAndFavorites';
import { quoteSlicer } from '../utils/quoteSlicer';
import TransitionButton from '../../themes/TransitionButton';
import { quoteContentsettings } from './quoteContentSettings';
import { useNavigate } from 'react-router-dom';




type QuoteCardProps = {
  AuthorQuotes: [Author, Quote[]]
  ref?: any
}






export default function QuoteCard({ AuthorQuotes }: QuoteCardProps) {
  
  
  let authCtx = React.useContext(AuthContext)
  const user = authCtx.user
  const [expanded, setExpanded] = React.useState(false);

  const [currentQuote, setCurrentQuote] = React.useState(0)
  const [currentAuthor, setCurrentAuthor] = React.useState(AuthorQuotes[0])
  const [quotes, setQuotes] = React.useState(AuthorQuotes[1])

  const [quoteHeight, setQuoteHeight] = React.useState<string>("120px")
  const [expandContent, setExpandContent] = React.useState<string>('')
  const navigate = useNavigate()
  const currentQuoteRef = React.useRef(null)


  async function quoteActions(action: string) {

    function rerenderData(updatedUser: registeredUserInt, updatedQuote?: Quote) {
      authCtx.login(updatedUser)
      if (updatedQuote) {
        setQuotes((prevValues) => {
          let _quotes = [...prevValues]
          for (let quote of quotes) {
            if (quote.id === updatedQuote.id) {
              quote = updatedQuote
            }
          }
          return _quotes
        })
      }

    }
    if (action === 'dislike') {
      let updatedUserAndQuote = await dislikeQuote(user, quotes[currentQuote])
      let updatedUser = updatedUserAndQuote[0] as registeredUserInt
      let updatedQuote = updatedUserAndQuote[1] as Quote
      rerenderData(updatedUser, updatedQuote)

    } else if (action === 'like') {
      let updatedUserAndQuote = await likeQuote(user, quotes[currentQuote])
      let updatedUser = updatedUserAndQuote[0] as registeredUserInt
      let updatedQuote = updatedUserAndQuote[1] as Quote
      rerenderData(updatedUser, updatedQuote)
    } else if (action === 'addFavorite') {
      let updatedUser = await addFavoriteQuote(user, quotes[currentQuote])
      rerenderData(updatedUser)

    } else if (action === 'removeFavorite') {
      let updatedUser = await removeFavoriteQuote(user, quotes[currentQuote])
      rerenderData(updatedUser)
    }
  }
 


  return (<>
    {(AuthorQuotes && quotes.length > 0) &&
      <Card sx={{
        width: "340px", borderRadius: "10px", boxShadow: `#bdbdbd 2px 2px 2px 2px`,
        '&:hover': {
          boxShadow: `#546e7a 3px 3px 3px 3px`
        },
        '&.MuiPaper-root': {
          marginBottom: "15px"
        }
      }

      }>
        <CardHeader
          sx={{
            '&.MuiPaper-root': {
              width: "100%"
            },
            '&.MuiCardHeader-root': {
              padding: "5px"
            },

            '&.MuiCardHeader-content': {
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              height: "130px"
            }
          }}

          avatar={
            <>

              <Avatar aria-label="recipe"

                onClick={() => { navigate(`/authors/${currentAuthor.id}`) }}
                sx={
                  {
                    boxShadow: "2px 2px 2px",

                    '&.MuiCardHeader-avatar': {
                      width: "150px"
                    },
                    '&.MuiAvatar-root': {
                      width: '140px',
                      height: "120px",
                      borderRadius: "0px"
                    }
                  }}

                src={AuthorQuotes[0].mainPhoto}
              />
            </>
          }
          title={
            <>
              <div style={{ display: "flex", flexDirection: "column", textAlign: "center", alignContent: "start", height: "100px" }}>
                <div style={{ position: "relative", cursor: "help" }}

                >
                  <b style={{ fontSize: "1rem", color: "#1e1e1e", cursor: "help" }}
                    onClick={() => navigate(`/authors/${AuthorQuotes[0].id}`)}>
                    {` ${AuthorQuotes[0].name}`}
                  </b><br></br>
                  {AuthorQuotes[0].occupation && <i><b>({AuthorQuotes[0].occupation})</b></i>}
                  <br></br>
                  {AuthorQuotes[0].timeOfLiving && <b>{AuthorQuotes[0].timeOfLiving}</b>}
                </div>

              </div>
            </>}

        />
        {/* <CardContent sx={
        {
          display: "flex",
          alignItems: "center",
          height: quoteHeight,
          padding: "0px",
          margin: "10px auto",
         
        }}> */}
        <div style={{ margin: "10px auto", paddingLeft: "13px", paddingRight: "13px", textAlign: "center", cursor:"pointer" }}
          onClick={() => {           
            if (quoteHeight === "120px") {
              setQuoteHeight('250px')
            } else {
              setQuoteHeight('120px')
            }
          }}
        >
          <Slider beforeChange={(oldIndex, newIndex) => {
              setCurrentQuote(newIndex)
          } } 
          {...quoteContentsettings}>
            {quotes.map((quote, i) => (
              <>
              <div style={{height:quoteHeight, display:"flex", flexDirection:"column", justifyContent:"center", fontSize:"1.15rem"}} key={quote.id} id={`${i}`} ref={currentQuoteRef}>                
                {quoteHeight === "120px" && quotes[i].content.length > 120 ?
                  <p >"{quoteSlicer(quotes[i].content)}" <i style={{color:"#B3B3B3"}}>(Click for more)</i></p>
                  : <p >"{quotes[i].content}"</p>}
              </div>
              </>
            )
            )}
          </Slider>
        </div>
        {/* <NavigateBeforeIcon
          className={quotes[currentQuote - 1] ? "visibleArrow" : 'hiddenArrow'}
          sx={{
            height: "35px", fontSize: "2rem", color: "#00AFF0", cursor: "pointer"
          }}
          onClick={() => {
            if (quotes[currentQuote - 1]) {
              setCurrentQuote(currentQuote - 1)
            }

          }}
        />
        <Typography variant="body2" color="text.secondary" className='wrapper' sx={{
          '&.MuiTypography-root': {
            textAlign: "center",
            fontWeight: "700",
            fontSize: "0.95rem",
            color: "#1e1e1e",
            width: "95%",
            margin: "10px auto",
            padding: "0",
            cursor: "help",
          }
        }}
          onClick={() => {
            if (quoteHeight === "120px") {
              setQuoteHeight('250px')
            } else {
              setQuoteHeight('120px')
            }

          }}
        >
          {quoteHeight === '120px' && quotes[currentQuote].content.length > 120 ?
          <i className='typing-demo'>'{quoteSlicer(quotes[currentQuote].content)}'</i>
          : <i className='typing-demo'>'{quotes[currentQuote].content}'</i>}
          <br></br>
          <span style={{ color: "#00AFF0", marginTop: "10px" }}>{currentQuote + 1} / {quotes.length} quotes.</span>

        </Typography>
        <NavigateNextIcon
          className={quotes[currentQuote + 1] ? "visibleArrow" : 'hiddenArrow'}
          sx={{ height: "35px", fontSize: "2rem", color: "#00AFF0", cursor: "pointer" }}
          onClick={() => {
            if (quotes[currentQuote + 1]) {
              setCurrentQuote(currentQuote + 1)
            }
          }}
        />
      </CardContent> */}
        <CardActions disableSpacing sx={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", marginTop:"20px" }}>
          {/* user actions comment */}
          <div style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
            <div style={{ width: "50%" }}>
              {user &&
                (user.likedQuotes.includes(quotes[currentQuote].id)
                  ?
                  <TransitionButton   >
                    <FavoriteIcon onClick={() => {
                      setExpandContent('Disliked!')
                      setExpanded(true)
                      quoteActions('dislike')
                      setTimeout(() => { setExpanded(false) }, 1500)
                    }} sx={{ color: "secondary.main" }}
                    />
                  </TransitionButton>
                  :
                  <TransitionButton >
                    <FavoriteBorderIcon
                      onClick={() => {
                        setExpandContent('Liked!')
                        setExpanded(true)
                        quoteActions('like')
                        setTimeout(() => { setExpanded(false) }, 1500)
                      }}
                    />
                  </TransitionButton>)
              }
              {!user &&
                <TransitionButton >
                  <FavoriteBorderIcon onClick={() => { navigate('/auth') }} />
                </TransitionButton>}

              {user &&
                (user.favoriteQuotes?.includes(quotes[currentQuote].id) ?
                  <TransitionButton  >
                    <BookmarkIcon sx={{ color: "primary.main" }}
                      onClick={() => {
                        setExpanded(true)
                        quoteActions('removeFavorite')
                        setExpandContent('Removed from favorites!')
                        setTimeout(() => { setExpanded(false) }, 1500)
                      }}
                    />
                  </TransitionButton>

                  :
                  <TransitionButton >
                    <BookmarkBorderIcon 
                      onClick={() => {
                        setExpanded(true)
                        quoteActions('addFavorite')
                        setExpandContent('Added to favorites!')
                        setTimeout(() => { setExpanded(false) }, 1500)
                      }}
                    />
                  </TransitionButton>)}
              {!user && <TransitionButton  ><BookmarkBorderIcon onClick={() => { navigate('/auth') }} /></TransitionButton>}
              <TransitionButton >
                <ContentCopyIcon
                  onClick={() => {
                    
                    setExpanded(true)
                    navigator.clipboard.writeText(`https://onlythoughts.org/quotes/${quotes[currentQuote].id}`)

                    setExpandContent('Link copied!')
                    setTimeout(() => { setExpanded(false) }, 1500)
                  }
                  }
                />
              </TransitionButton>
              <TransitionButton  >
                <ChatBubbleOutlineIcon
                  onClick={() => {
                    navigate(`/quotes/${quotes[currentQuote].id}/comments`)
                  }}
                />
              </TransitionButton>

            </div>
            <div className={expanded ? "showQuoteAction" : "hideQuoteAction"} >
              <span style={{ fontFamily: 'Alfa Slab One, cursive', fontWeight: "300", color: "primary.main" }}>{expandContent}</span>
            </div>


          </div>
          <div style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
            <span>{quotes[currentQuote]?.likes} likes</span>
          </div>
        </CardActions>

        <Collapse timeout={{ appear: 1000, enter: 1000, exit: 2000 }} unmountOnExit>
          <CardContent sx={{ textAlign: "center", color: "secondary.main", height: "20px", padding: "0px", margin: "0px" }}>
            {expandContent}
          </CardContent>
        </Collapse>

      </Card>
    }

  </>
  );
}