import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import SearchIcon from '@mui/icons-material/Search';
import { useLocation, useNavigate } from 'react-router-dom';

export default function Navigation() {
  const [value, setValue] = React.useState(0);
  const arrStrNav = ["Search",'Trending', 'Authors']
  const path = useLocation()

  const buttonRef = React.useRef()
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {

    setValue(newValue);
  };
  const navigate = useNavigate()
  const handleNavChange = (tab: any, i: number) => {
    if (tab === 'Trending') {
      navigate('/')
    } else if (tab === 'Authors') {
      navigate('/authors')
    }else if( tab === 'Search'){
      navigate('/search')
    }
  }
  React.useEffect(() => {
    if(path.pathname === '/search'){
      handleChange(buttonRef.current,0)
    }
    if (path.pathname === '/') {
      handleChange(buttonRef.current, 1)
    } else if (path.pathname.includes('authors')) {
      handleChange(buttonRef.current, 2)
    }

  }, [path])



  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Tabs value={value} onChange={handleChange} centered>
        {arrStrNav.map((tab, i) =>
          <Tab ref={buttonRef} onClick={() => {
            handleNavChange(tab, i)
          }}
            key={i} label={tab !== "Search" ? tab : ''}
            icon={tab === "Search" && <SearchIcon/>}
            sx={{ padding: "5px", minWidth:"50px" }}  />       
         
              
              )}
      </Tabs>
    </Box>
  );
}


