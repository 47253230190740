
import { Control, Controller, FieldPath, FieldValues, Path, RegisterOptions } from "react-hook-form";

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import FemaleIcon from '@mui/icons-material/Female';
import MaleIcon from '@mui/icons-material/Male';

import { genderStatus, userRole, userStatus } from '../../models/login-register-model';
import { GenderSelectOption } from '../../outlets/RegisterForm';

interface FormInputTextProps<TFieldValues extends FieldValues> {
    name: Path<TFieldValues>;
    control: Control<TFieldValues, any>;
    label: string;
    rules?: Omit<RegisterOptions<TFieldValues, FieldPath<TFieldValues>>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
    disabled?: boolean;
    size?: 'small' | 'medium';
    error?: string | undefined;
    type?: string
    variant?: 'standard' | 'outlined' | 'filled' | undefined,
    options: GenderSelectOption[],
    defaultOption: genderStatus | userStatus | userRole
}

function FormInputText<TFieldValues extends FieldValues>(
    { name, control, label, rules = {}, disabled = false, size = 'small', error=undefined, variant="standard", options=[], defaultOption}: FormInputTextProps<TFieldValues>) {
        
    return (
        (
            <Controller
                name={name}
                control={control}
                
                render={({ field }) =>(
                    <FormControl sx={{margin:"10px 15px"}} size = {size}>
                    <InputLabel id={name + '-label'}>{label}</InputLabel>
                    <Select 
                        labelId={name + '-label'}
                        id={name}
                        label={label}
                        defaultValue={defaultOption[1]}
                        {...field}
                        
                    >
                        {options.map((option, index) =>{
                            
                          return  (<MenuItem key={option.key} value={option.key} >                            
                                {(typeof defaultOption === typeof genderStatus) && option.key === 2 ? <FemaleIcon /> : <MaleIcon />}
                              {option.value}
                            </MenuItem>)
                            })
                        }
                    </Select>
                </FormControl>
                )
                }
                rules={rules}
            />
        )
    )
}

export default FormInputText