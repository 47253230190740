
import {  useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import AuthContext from '../../contexts/userContext';



import { registeredUserInt } from '../../models/login-register-model';



export type ContextType = { users: registeredUserInt[] | null,
  user: registeredUserInt | undefined};

function LoginPage() {
  
  const authCtx = useContext(AuthContext)
  const user = authCtx.user
  const navigate = useNavigate()
  useEffect(()=>{
 
    
    if(user){
      navigate('/')
    }else{
      navigate('login')
    }
    
  },[])
  
    
  return (    
  
         
      <Outlet  />
      
        
  
  );
}

export default LoginPage;
