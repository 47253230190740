import React from 'react'

function AboutPage() {
  return (
    <div style={{width:"70%"}}>
        <h3><i> Only thoughts is web app, where you can like, comment and share the greatest thoughts of the greatest persons ever lived with your friends or family.</i></h3>
        <p>Created by Teodor Vergov</p>
    </div>
  )
}

export default AboutPage