import { IdType } from "./quotes-author-model"


export enum userRole{
    User = 1, Admin
}
export enum userStatus{
    Active = 1, Suspended, Deactivated
}
export enum genderStatus{
    Male =1,Female
}

export interface ApiRes{
    token: string,
    user: registeredUserInt,
}
export interface registeringUserInt {
    firstName: string,
    lastName: string,
    userName: string,
    gender?: genderStatus,
    password: string,
    password2: string,
    role: userRole,
    status: userStatus,
    imgUrl: string,
    description?: string,
    timeOfRegistration: string,
    timeOfLastUpdate: string,
    likedQuotes?: IdType[],
    favoriteQuotes?: [],
    token?: string

}
export interface registeredUserInt {
    id: IdType,
    firstName: string,
    lastName: string,
    userName: string,
    gender: genderStatus,
    password: string,
    password2: string,
    role: userRole,
    status: userStatus,
    imgUrl: string,    
    newImgUrl?: string,
    description?: string,
    token?: string,
    timeOfRegistration: string,
    timeOfLastUpdate: string,
    favoriteQuotes: IdType[],
    likedQuotes: IdType[]

}
