import { createTheme } from '@mui/material'
import { red, yellow, green, blue, orange } from '@mui/material/colors'

export const mainTheme = createTheme({    
  palette: {
    primary: {
      main: '#018CF1',
    },
    secondary: {
      main: '#00AFF0',
    },
    success: {
      dark: '#ffeb3b',
      light: '#fff9c4',
      main: "#fff176"
    },   
    background: {
      default: '#fafafa',
    },
  },
})