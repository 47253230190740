import { ApiRes } from "./models/login-register-model";

const API_BASE_URL = process.env.REACT_APP_HEROKU_AUTH_API || "http://localhost:4006/api/auth"


export interface userCredentials{
    userName: string,
    password: string
}

interface ApiAuthClient<V extends userCredentials>{   
    login(user: V): Promise<ApiRes>,
    register(user: V): Promise<ApiRes>
   
}

export class ApiAuthImpl<V extends userCredentials> implements ApiAuthClient<V>{
    
    login(user: V): Promise<ApiRes> {
        return this.handleRequest(`${API_BASE_URL}/login`,{
            method: 'POST',
            mode: 'cors',
            headers: {
                'content-type': 'application/json', 
                  'Access-Control-Allow-Origin':'www.onlythoughts.org'
            },
            body: JSON.stringify(user)
        })
    }
    register(user: V): Promise<ApiRes>{
        return this.handleRequest(`${API_BASE_URL}/register`,{
            method: 'POST',
            mode: 'cors',
            headers: {
                'content-type': 'application/json', 
                  'Access-Control-Allow-Origin':'www.onlythoughts.org'
            },
            body: JSON.stringify(user)
        })
    }
    private async handleRequest(url: string, options?: RequestInit){
        try{
            const resp = await fetch(url, options);
            if(resp.status >= 400){
                return Promise.reject(resp.body);
            }
            return resp.json()

        }catch(err){
            return Promise.reject(err)
        }
    }
}
export const AuthApi: ApiAuthClient<userCredentials> = new ApiAuthImpl()