import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import useMainFetchAndSort from '../customHooks/useMainFetchAndSort';
import { registeredUserInt } from '../models/login-register-model';
import { UsersApi } from '../rest-api';



const AuthContext = React.createContext({
    user: undefined,
    login: (user: registeredUserInt) => { },
    logout: () => { },
    users: null,
    handleGetUsers: (users: registeredUserInt[]) => { },
    authorsAndQuotes: [],
    isLoading: false,
    authors: [],
    reload: false,
    setReload: (bool: boolean) => { },
    setSkip: (number) => {},
    isMoreData: true
 
});
// i need to add usersArr to my context? 

export const AuthContextProvider = (props: any) => {
    const [cookies, setCookie, removeCookie] = useCookies(['OTauth']);
    const [user, setUser] = useState<registeredUserInt | undefined>(undefined)
    const [users, setUsers] = useState<registeredUserInt[] | null>(null)
    const { authorsAndQuotes, isLoading, authors, reload, setReload,setSkip,isMoreData } = useMainFetchAndSort()
    const loginHandler = (user: registeredUserInt) => {
        setUser(user)
    }
    const checkForCookieAndLogUser = async () => {
        if (cookies["OTauth"]?.hasOwnProperty('id')) {          
            try {
                console.log(cookies["OTauth"].id)
                let loggedUser = await UsersApi.findById(cookies['OTauth'].id)
                console.log(loggedUser)
                if(cookies["OTauth"]?.hasOwnProperty('token')){
                    loggedUser.token = cookies['OTauth'].token
                }
                loginHandler(loggedUser)
            } catch (err) {
                console.log(err)
            }
        }

    }
    useEffect(()=>{
        checkForCookieAndLogUser()
    },[])
   
    const logoutHandler = () => {
        setUser(undefined)
        removeCookie("OTauth")
    }
    const handleGetUsers = (users: registeredUserInt[]) => {
        setUsers(users)
    }

    const contextValue = {
        user: user,
        users: users,
        login: loginHandler,
        logout: logoutHandler,
        handleGetUsers: handleGetUsers,
        authorsAndQuotes: authorsAndQuotes,
        isLoading: isLoading,
        authors: authors,
        reload: reload,
        setReload: setReload,
        setSkip: setSkip,
        isMoreData: isMoreData
    }

    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
}

export default AuthContext
