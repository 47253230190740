import { Box, Button } from "@mui/material";
import  {  useState } from "react";
import { BaseSyntheticEvent, FormEvent } from "react";
import { useForm } from "react-hook-form";


import ClearIcon from '@mui/icons-material/Clear';
import SendIcon from '@mui/icons-material/Send';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { registeredUserInt } from "../../models/login-register-model";
import { addDataAndValidateNewQuote } from "../../validations/validation";
import { QuotesApi } from "../../rest-api";
import FormInputText from "../loginComponents/FormInputText";
import { Author, Quote, RegisteringQuote } from "../../models/quotes-author-model";



interface AddQuoteFormProps{
    quotes: [] | Quote[],
    formHandler: () => void,
    authors: [] | Author[],
    appUser: registeredUserInt | undefined,
    reloadAuthorsHandler: () => void,
}    

function AddQuoteForm({quotes, formHandler, reloadAuthorsHandler, authors, appUser}: AddQuoteFormProps) {
    const registeringSchema = yup.object({
        content:yup.string().required().min(3).max(300),
        author: yup.string().min(2).max(150),    
    }).required();
    
    const defaultRegisteringQuote = { content: "", author:""}
    const [registeringQuote, setRegisteringQuote] = useState<RegisteringQuote>(defaultRegisteringQuote)
    const [schema, setSchema] = useState(registeringSchema)
    const [errorMsg, setErrorMsg] = useState('')
   
    

    const { control, handleSubmit, reset, formState: { errors } } = useForm<Partial<RegisteringQuote>>({
        defaultValues: { ...registeringQuote },
        mode: 'onChange',
        resolver: yupResolver(schema),
    });
    const onSubmit = async  (data: RegisteringQuote, event: BaseSyntheticEvent<object, any, any> | undefined) => {
        event?.preventDefault();             
        try{
            let newQuote: RegisteringQuote | string = addDataAndValidateNewQuote(data, authors, appUser )
            if(typeof newQuote == 'string'){
             
                setErrorMsg(newQuote)
            }else{
               
                await QuotesApi.create(newQuote, appUser.token)                
                reset()
                formHandler()
                reloadAuthorsHandler()
            }
            
           
        }catch(err){
            setErrorMsg(err)
        }    
    

    }
    const onReset = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();        
        reset()

    }
  return (
    
    <Box component='form' sx={{ paddingLeft: "30px", paddingRight: "30px", paddingTop: "15px", border:"#00AFF0 solid 1px", borderRadius:"25px", 
    margin:"15px auto"
     }} 
     onSubmit={handleSubmit(onSubmit)} onReset={onReset}>
        {registeringQuote &&
            <div style={{ textAlign: "center" }}>
                <h3 style={{margin:"3px"}}>New quote form</h3>
                <FormInputText label="Content:" type="text" multiline={true} size="medium" name='content' control={control} error={errors.content?.message}></FormInputText>
                <FormInputText label="Author name:" type="text" size="medium" name='author' control={control} error={errors.author?.message}></FormInputText>
                <div><h4 style={{color:"red"}}>{errorMsg && errorMsg}</h4></div>
                <div style={{ justifyContent: "center", display: "flex", margin:"20px auto" }}>
                    <Button variant="outlined"
                        type="reset"
                        color="warning"
                        size="large"

                    >
                        Reset
                        <ClearIcon></ClearIcon>
                    </Button>
                    <Button variant="outlined"
                        type='submit'
                        size="large"
                        sx={{ marginLeft: "20px" }}
                    >Register <SendIcon></SendIcon>
                    </Button>

                </div>
            </div>}
    </Box>
    )
  
}

export default AddQuoteForm