import { Button, useMediaQuery, useTheme } from '@mui/material'
import React, { useContext, useState } from 'react'
import AddAuthorForm from '../components/adminComponents/AddAuthorForm'
import AdminAuthorCard from '../components/adminComponents/AdminAuthorCard'
import AuthContext from '../contexts/userContext'
import { MainDiv } from '../mediaQuery/MainDiv'


function AdminAuthors() {
  const theme = useTheme()
  const matchesSmall = useMediaQuery(theme.breakpoints.up('sm'));
    const [addAuthorForm, setAddAuthorForm] = useState<boolean>(false)
    const authCtx = useContext(AuthContext)
    const { authors, reload, setReload } = authCtx
    const [admin, setIsAdmin] = useState(true)
    
    function reloadAuthorsHandler() {
        setReload(!reload)
      }
      function formHandler() {
        setAddAuthorForm(!addAuthorForm)
      }
  
      console.log(authors)

  return (
     <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center" }}>
        <h3 style={{margin:"0 auto"}}>Admin Authors:</h3>
        
        <div style={!matchesSmall ? { display:"flex", flexDirection:"column", alignItems:"center", margin:"0 auto"} 
                           : {display:"flex", flexDirection:"column", alignItems:"center", margin:"0 auto", width:"50%"}}>
        { authors &&
            <Button onClick={() => {
                setAddAuthorForm(!addAuthorForm)
              }} sx={{ margin: "5px auto" }} variant="outlined">{!addAuthorForm ? "Add new Author" : "Cancel"}
            </Button>}
        {addAuthorForm && <AddAuthorForm reloadAuthorsHandler={reloadAuthorsHandler} authors={authors} formHandler={formHandler} />}
        </div>
        <MainDiv>
        {authors && authors.map(author => <AdminAuthorCard admin={admin} reloadAuthorsHandler={reloadAuthorsHandler} key={author.id} author={author} />)}
        </MainDiv>
        </div>
   
   
  )
}

export default AdminAuthors