import { useEffect,  useState } from "react"
import { Author, Quote } from "../models/quotes-author-model"
import { AuthorsApi, QuotesApi } from "../rest-api"



const useMainFetchAndSort = ()=>{
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')
    const [quotes, setQuotes] = useState([] as Quote[])
    const [authors, setAuthors] = useState([])
    const [authorsAndQuotes, setAuthorsAndQuotes] = useState([])
    const [reload, setReload] = useState<boolean>(false)
    const [skip, setSkip] = useState(0)
    const [isMoreData, setIsMoreData] = useState(true)
    //maybe i will have to update this function
    // function sortAndFilterQuotes(allAuthors: Author[], allQuotes: Quote[]){
    //     let authorsAndQuotes = []
    //     let authors = []
        
    //     for(let author of allAuthors){
    //         authors.push(author)
    //         let authorWithQuotes = []
    //      let authorQuotes =   allQuotes.filter((quote: Quote)=> {
    //         let sameAuthor = false
    //         if(author.id ===quote.authorId){
    //             sameAuthor = true
    //             if(authorWithQuotes.length === 0){
    //                 authorWithQuotes.push(author)
    //             }
                
    //         }
    //             return sameAuthor
    //         })
            
    //         authorWithQuotes.push(authorQuotes)
    //         if(authorQuotes.length !== 0){
    //             authorsAndQuotes.push(authorWithQuotes)
    //         }
            
    //     }
      
        
    //     return authorsAndQuotes
    // }

    useEffect(()=>{
       let subscribed = true;
        async function getAllData(){
            setIsLoading(true)
                               
            // let apiAuthors: Author[] = await AuthorsApi.findAll() 
            // console.log(apiAuthors)
            if(subscribed){
               
                  
                // const filteredData = sortAndFilterQuotes(apiAuthors, apiQuotes)
                // filteredData && filteredData.sort((a,b) => b[1][0].likes -a[1][0].likes)  
                console.log('skip value in reloading')
                console.log(skip)    
                const allData = await AuthorsApi.getTenMixed(skip,10)     
                if(authorsAndQuotes.length > 0){
                    setAuthorsAndQuotes([...authorsAndQuotes, ...allData])
                }else{
                    setAuthorsAndQuotes(allData)
                }
                if(allData.length === 0){
                    setIsMoreData(false)
                }              
                
                
            }
            
            setIsLoading(false)
        }      
        if(isMoreData){
            getAllData()
         
        }        
       
       
       return () =>{
        subscribed = false;
       }
       
    },[reload])


    useEffect(()=>{
        function getAuthors(){
            const authorsArr = []
            if(authorsAndQuotes){
                for(let author of authorsAndQuotes){
                    authorsArr.push(author[0])
                }
                setAuthors(authorsArr)
            }
        }
        getAuthors()
        setSkip(authorsAndQuotes.length)
    },[authorsAndQuotes])

    return {isLoading,
            error,
            quotes,
            authors,
            authorsAndQuotes,
            setReload,
            reload,
            setSkip,
            skip,
            isMoreData
            }
}

export default useMainFetchAndSort