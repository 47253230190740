import { registeredUserInt, registeringUserInt } from "../models/login-register-model"
import { Author, Quote, RegisteringAuthor, RegisteringQuote } from "../models/quotes-author-model"
export function addDataBeforeApi(data: Partial<registeringUserInt>) {
  let registeringUser = { ...data }
  if (registeringUser.gender === 1) {
    registeringUser.imgUrl = "https://as2.ftcdn.net/v2/jpg/02/23/50/73/1000_F_223507324_jKl7xbsaEdUjGr42WzQeSazKRighVDU4.jpg"
  } else if (registeringUser.gender === 2) {
    registeringUser.imgUrl = "https://as1.ftcdn.net/v2/jpg/02/32/92/20/1000_F_232922056_g0vTm6q6681RcxFVbIUKMgmbXWZ1oYCL.jpg"
  }
  let today = new Date().toISOString()
  
  registeringUser.timeOfRegistration = today
  if(registeringUser.password2){
    delete registeringUser.password2
  }
  
  registeringUser.timeOfLastUpdate = today
  registeringUser.role = 1
  registeringUser.status = 1
  registeringUser.likedQuotes = []
  registeringUser.favoriteQuotes = []

  return registeringUser
}
export function addDataBeforeUserEditApi(data: registeredUserInt, users: registeredUserInt[]) {
  let editedUser = { ...data }
  let err = ''
  let usernameTaken = false
  for (let user of users) {

    if (user.userName === editedUser.userName) {
      usernameTaken = true
      if (user.id === editedUser.id) {
        usernameTaken = false
        break
      } else {
        break
      }
    }
  }
  if (usernameTaken) {
    err = 'Username is already taken, please choose another one!'
    return err
  } else {
    let today = new Date().toISOString()
    if (editedUser?.newImgUrl !== "") {
      editedUser.imgUrl = editedUser.newImgUrl
      editedUser.newImgUrl = ''
    }
    editedUser.timeOfLastUpdate = today
  }
  return editedUser
}

export function validateRegister(users: registeredUserInt[], registeringUser: Partial<registeringUserInt>) {
  let error = ''
  for (let user of users) {
    if (user.userName === registeringUser.userName) {
      error = 'username is already taken, please choose another one.'
      return error
    }
  }
  return registeringUser
}
export function validateAuthor(authors: Author[], registeringAuthor: RegisteringAuthor) {
  let error = ''
  for (let author of authors) {
    if (author.name === registeringAuthor.name) {
      error = 'This author is already registered.'
      return error
    }
  }
  return registeringAuthor
}
export function addDataAndValidateNewQuote(quote: RegisteringQuote, authors: Author[], appUser: registeredUserInt) {
  let registeringQuote = { ...quote }

  let author = authors.find(author => {
   
    if(author.name === quote.author){
      return author
    }})
  if (author) {
    registeringQuote.authorId = author.id

    let today = new Date().toISOString()
    if (!!registeringQuote.timeOfRegistration) {
      registeringQuote.timeOfRegistration = today
    }
    registeringQuote.timeOfLastUpdate = today
    registeringQuote.addedBy = appUser.userName
    registeringQuote.likes = 0
    registeringQuote.comments = []

    let quoteWithAddedData = registeringQuote
    return quoteWithAddedData
  }
  let errMsg =  "This author does not exist yet. Please create author first."
 
  return errMsg

}

export function validateLogin(users: registeredUserInt[], loginInfo: { userName: string, password: string }) {

  for (let user of users) {
    if (user.userName === loginInfo.userName) {
      if (user.password === loginInfo.password) {
        return user
      }
    }
  }
  let errorMsg = "You have entered wrong username or password. Please try again."
  return errorMsg
}