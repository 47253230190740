import * as React from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useLocation, useNavigate } from 'react-router-dom';


export default function AdminNav() {
    const [value, setValue] = React.useState(0);
    const buttonRef = React.useRef()
    const params = useLocation()
    const arrStrNav = ['Users', 'Quotes', 'Authors']
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      
      setValue(newValue);
    };
    const navigate = useNavigate()
    const handleNavChange = (tab: string) =>{
        if(tab === "Users"){
            navigate('/admin/users')
        }else if(tab === 'Quotes'){
            navigate('/admin/quotes')
        }else if(tab ==='Authors'){
          navigate('/admin/authors')
        }
    }
    React.useEffect(()=>{  
        if(params.pathname === '/admin'){
          handleNavChange('Users')
        }
        if(params.pathname === '/admin/users' || params.pathname ==='/admin'){
         
            handleChange(buttonRef.current, 0)
        }else if(params.pathname === '/admin/quotes'){
          handleChange(buttonRef.current, 1)
        }else if(params.pathname === '/admin/authors'){
          handleChange(buttonRef.current, 2)
        }
        
       
       
    },[params])
 

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
      <Tabs value={value} onChange={handleChange} centered >
        {arrStrNav.map((tab,i)=> 
        <Tab ref={buttonRef} onClick={()=>{
            handleNavChange(tab)
        }}
         key={i} label={tab} sx={{padding:"1px"}} />)}
      </Tabs>
    </Box>
  );
}