import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import QuoteCard from '../../components/mainComponents/QuoteCard'
import AuthContext from '../../contexts/userContext'

function FavoritesPage() {
const authCtx = useContext(AuthContext)
  const {user, authorsAndQuotes, authors} = authCtx
  const navigate = useNavigate()
  const [favoriteAuthorsAndQuotesState, setFavoriteAuthorsAndQuotesState] = useState(null)
  function getFavoriteAuthorsAndQuotes(){
    const favoriteQuotesIds = user.favoriteQuotes
  
  const favoriteAuthorsAndQuotes = []
  const favoriteQuotes = []
  const favoriteAuthorsIds = []
  const favoriteAuthors = []
  for(let authorAndQuotes of authorsAndQuotes){
        for(let quote of authorAndQuotes[1]){
            if(favoriteQuotesIds.includes(quote.id)){
                favoriteQuotes.push(quote)
                if(!favoriteAuthorsIds.includes(quote.authorId)){
                    favoriteAuthorsIds.push(quote.authorId)
                }
            }
        }
  }
  for(let author of authors){
        if(favoriteAuthorsIds.includes(author.id)){
            favoriteAuthors.push(author)
        }
  }
  for(let author of favoriteAuthors){
    const favAuthorWithQuotes = []
    favAuthorWithQuotes.push(author)
    const favAuthorQuotes = []
    for(let quote of favoriteQuotes){
        if(quote.authorId === author.id){
            favAuthorQuotes.push(quote)
        }
    }
    favAuthorWithQuotes.push(favAuthorQuotes)
    favoriteAuthorsAndQuotes.push(favAuthorWithQuotes)
  }
    return favoriteAuthorsAndQuotes
  }
  
  
  

  useEffect(()=>{  
    let subscribed = true  
    if(!user){
        navigate('/')
    }else{
       let apiData = getFavoriteAuthorsAndQuotes()
        if(subscribed){
          setFavoriteAuthorsAndQuotesState(apiData)
        }
    }
    return () =>{
      subscribed = false
    }
    
  },[user])
  return (
    <>    
        
        {user?.favoriteQuotes?.length > 0 && favoriteAuthorsAndQuotesState ?    
             favoriteAuthorsAndQuotesState.map(authorQuotes => <QuoteCard key={authorQuotes[1][0].id} AuthorQuotes={authorQuotes} />)
        : <p>You dont have favorite quotes yet.</p>}
    
    </>
  )
}

export default FavoritesPage