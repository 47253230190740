import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';


import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { AuthorsApi } from '../../rest-api';
import { Author } from '../../models/quotes-author-model';
import TransitionButton from '../../themes/TransitionButton';
import { Button } from '@mui/material';
import AuthContext from '../../contexts/userContext';
// import MuiEditUserModal from './MuiEditUserModal';

interface ExpandMoreProps extends IconButtonProps {
    expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));
interface AuthorCardProps {
    author?: Author,
    admin: boolean,
    reloadAuthorsHandler: () => void
}

export default function RecipeReviewCard({ author, admin, reloadAuthorsHandler }: AuthorCardProps) {
    const navigate = useNavigate()
    const { authorId } = useParams()
    const [currentAuthor, setCurrentAuthor] = React.useState(author)
    console.log(currentAuthor)
    const [error, setError] = React.useState('')
    const [expandContent, setExpandContent] = React.useState('')
    const [isVideo, setIsVideo] = React.useState(false)
    const [expanded, setExpanded] = React.useState(false);
    const [expandedDelete, setExpandedDelete] = React.useState(false)
    const [bigPhoto, setBigPhoto] = React.useState<string | undefined>(undefined)
    let authCtx = React.useContext(AuthContext)
    const token = authCtx.user.token
    useEffect(() => {
        console.log('admin author card use effect')
        let subscribed = true
        async function getAuthorWithParams() {
            try {
                if (!currentAuthor) {
                    let apiReq = await AuthorsApi.findById(authorId)
                    if (apiReq.id) {
                        if (subscribed) {
                            setCurrentAuthor(apiReq)
                        }

                    } else {
                        throw new Response("Not Found", { status: 404 });
                    }
                }
            }
            catch (err) {
                setError('Author not found')
            }
        }

        getAuthorWithParams()
        if (currentAuthor) {
            setBigPhoto(currentAuthor.mainPhoto)
        }

        return () => {
            subscribed = false
        }
    }, [authorId, currentAuthor, error])




    const handleExpandClick = (content: string) => {
        let innerContent = currentAuthor[content]
        setIsVideo(false)
        if (Array.isArray(innerContent)) {
            if (content === 'videos') {
                setIsVideo(true)
                let video = innerContent[0]
                innerContent = <iframe title={currentAuthor.name} style={{ width: "100%", height: "100%" }} src={video}></iframe>
            } else if (content === 'photoCollection') {

                if (content.length > 0) {
                    let photoContent = (<div style={{ display: "flex", flexWrap: "wrap", margin: "0 auto", columnGap: "5px", justifyContent: "center" }}>
                        {innerContent.map(photo =>
                            <img alt={currentAuthor.name} style={{ objectFit: "contain", width: "30%", height: "100px" }} key={photo.imgUrl} src={photo.imgUrl} onClick={(e) => {
                                e.stopPropagation()
                                setBigPhoto(photo.imgUrl)
                            }
                            } />)}
                    </div>)
                    innerContent = photoContent
                }


            } else {
                innerContent = innerContent.map((text: string, i: number) => <p key={i}>{i + 1}. "{text}"</p>)
            }

        }
        setExpandContent(innerContent)
        setExpanded(!expanded)

    };

    return (currentAuthor ?

        <Card sx={{
            borderRadius: "10px", border: "1px solid black", width: "350px", marginBottom: "15px",
            '&:hover': {
                boxShadow: `#546e7a 3px 3px 3px 3px`
            }
        }
        }>
            <CardContent sx={{
                display: "flex", alignItems: "center", height: "300px", flexDirection: "Column",
                '&.MuiCardContent-root': { padding: "0px", paddingTop: "10px" }
            }}
            >
                <h3 style={{ margin: "10px auto" }}>{currentAuthor.name}</h3>
                <img style={{
                    objectFit: "cover", objectPosition: "50% 0%",
                    width: "100%",
                    height: "85%"
                }}
                    onClick={() => { navigate(`/authors/${currentAuthor.id}`) }}
                    src={bigPhoto} alt="author" ></img>

            </CardContent>
            <CardActions disableSpacing  >
                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>

                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", rowGap: "1px" }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", columnGap: "15px" }}>
                            <h3 onClick={() => { handleExpandClick('biography') }}>Biography</h3>
                            <h3 onClick={() => { handleExpandClick('bestWorks') }}>Best works </h3>
                            <h3 onClick={() => { handleExpandClick('crazyFacts') }}>Crazy facts</h3>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", columnGap: "15px" }}>
                            <h3 onClick={() => { handleExpandClick('videos') }}>Videos</h3>
                            {currentAuthor?.photoCollection?.length > 0 && <h3 onClick={() => { handleExpandClick('photoCollection') }}>Photo collection</h3>}
                        </div>
                        <div>
                            <TransitionButton >
                                <DeleteOutlineIcon
                                    onClick={() => {
                                        setExpandedDelete(!expandedDelete)
                                    }}
                                ></DeleteOutlineIcon>
                            </TransitionButton>
                        </div>
                    </div>
                </div>
            </CardActions>

            <Collapse in={expanded} timeout="auto" unmountOnExit>
                {isVideo ? <CardContent sx={{ cursor: "pointer", width: "380px", height: "500px", padding: "0px" }} onClick={() => setExpanded(!expanded)}>
                    {expandContent}
                </CardContent> : <CardContent sx={{ cursor: "pointer", width: "90%" }} onClick={() => setExpanded(!expanded)}>
                    {expandContent}
                </CardContent>
                }

            </Collapse>
            <Collapse in={expandedDelete} timeout="auto" unmountOnExit>
                <CardContent sx={{ display: "flex", flexDirection: "column", textAlign: "center" }}>
                    <h4> Are you sure you want to delete this Author?</h4>
                    <div>
                        <Button
                            onClick={() => {
                                setExpandedDelete(false)
                            }}
                        >No</Button>
                        <Button
                            onClick={async () => {
                                await AuthorsApi.deleteById(author.id, token)
                                reloadAuthorsHandler()
                                setExpandedDelete(false)
                            }}
                        >Yes</Button>

                    </div>
                </CardContent>
            </Collapse>
        </Card> : <h3>{error}</h3>

    );
}