import React from 'react'
import { useRouteError } from 'react-router-dom';

interface errorType{
    message?: string
}
function ErrorPage() {
    const error: errorType = useRouteError();
    console.error(error);
  return (
    <div style={{display:"flex", flexDirection:"column", margin:"0 auto", width:"50%", textAlign:"center"}}><h3>Page not found!</h3></div>
  )
}

export default ErrorPage